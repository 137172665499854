import React from "react";
import Svg, { Ellipse, Path } from "svgs";

import { animalSize } from "../constants/board";

const Dog = (props) => (
  <Svg height={animalSize} width={animalSize} viewBox="0 0 64 64" {...props}>
    <Path
      fill="#EBBB96"
      d="M56.018 39.49c2.346 2.662 1.631 6.74 1.631 6.74-1.401-.674-2.94-.761-2.94-.761 1.104 2.995-.348 7.146-.348 7.146-1.159-2.4-2.855-3.761-2.855-3.761-.963 2.323-2.866 3.655-2.866 3.655-.158-2.357-2.021-3.754-2.021-3.754l9.399-9.265zm-48.036 0c-2.346 2.662-1.631 6.74-1.631 6.74 1.402-.674 2.94-.761 2.94-.761-1.104 2.995.348 7.146.348 7.146 1.159-2.4 2.854-3.761 2.854-3.761.964 2.323 2.867 3.655 2.867 3.655.159-2.357 2.02-3.754 2.02-3.754L7.982 39.49z"
    />
    <Path
      fill="#EBBB96"
      d="M56.939 30.693c0 22.944-17.994 24-24.939 24-6.944 0-24.939-1.057-24.939-24 0-13.255 17.996-24 24.939-24 6.945-.001 24.939 10.745 24.939 24z"
    />
    <Path
      fill="#F46C60"
      d="M32 43.168s7.639 9.418 6.728 13.984c-1.287 6.464-12.169 6.464-13.455 0C24.361 52.586 32 43.168 32 43.168z"
    />
    <Path fill="#E03636" d="M32 59.576l-1.702-9.11h3.404z" />
    <Path
      fill="#785647"
      d="M24.33 9.058c-8.987 4.787-8.676 23.592-14.164 28.813C7.335 21.802 20.57 4.705 24.33 9.058z"
    />
    <Path
      fill="#B47D56"
      d="M15.464 9.27c-6.618 4.397-17.056 5.05-12.23 16.142C6.56 33.057.22 44.588 5.069 42.193c13.706-6.77 4.768-28.046 19.261-33.136-.768-1.571-4.981-2.371-8.866.213z"
    />
    <Path
      fill="#785647"
      d="M39.67 9.021c8.988 4.786 8.676 23.629 14.164 28.851C56.665 21.802 43.43 4.667 39.67 9.021z"
    />
    <Path
      fill="#B47D56"
      d="M48.536 9.27c6.618 4.397 17.056 5.051 12.23 16.143-3.327 7.645 3.015 19.176-1.837 16.781-13.704-6.77-4.767-28.083-19.26-33.173.769-1.572 4.981-2.335 8.867.249z"
    />
    <Ellipse fill="#302C3B" cx={21.804} cy={30.102} rx={4.066} ry={5} />
    <Path
      fill="#FFF"
      d="M21.061 27.854c0 1.666-2.033 1.666-2.033 0s2.033-1.666 2.033 0z"
    />
    <Ellipse fill="#302C3B" cx={42.196} cy={30.102} rx={4.066} ry={5} />
    <Path
      fill="#FFF"
      d="M41.454 27.854c0 1.667-2.033 1.667-2.033 0 0-1.666 2.033-1.666 2.033 0z"
    />
    <Path
      fill="#C4926E"
      d="M47.565 46.209c0 13.742-13.918 7.375-15.565 5.82-1.648 1.555-15.567 7.922-15.567-5.82 0-12.436 31.132-12.436 31.132 0z"
    />
    <Path
      fill="#B47D56"
      d="M22.785 45.27c0 1.326-1.708 1.326-1.708 0s1.708-1.325 1.708 0zm-3.016 2.683c0 1.325-1.709 1.325-1.709 0 0-1.326 1.709-1.326 1.709 0zm3.502 2.215c0 1.323-1.708 1.323-1.708 0 0-1.326 1.708-1.326 1.708 0zm17.945-4.898c0 1.326 1.707 1.326 1.707 0s-1.707-1.325-1.707 0zm3.015 2.683c0 1.325 1.708 1.325 1.708 0 0-1.326-1.708-1.326-1.708 0zm-3.502 2.215c0 1.323 1.708 1.323 1.708 0 0-1.326-1.708-1.326-1.708 0z"
    />
    <Path
      fill="#EBBB96"
      d="M38 9.395c-.235-3.201-3.459-5.371-3.459-5.371-.48 1.321-1.443 2.354-1.443 2.354C31.813 3.75 28.05 2 28.05 2c.849 2.28.638 4.229.638 4.229-2.206-.882-4.37-.533-4.37-.533 1.484 1.618 1.188 3.698 1.188 3.698H38v.001z"
    />
    <Path
      fill="#302C3B"
      d="M23.733 42.051c0-3.631 3.702-4.33 8.268-4.33 4.566 0 8.268.699 8.268 4.33 0 2.889-6.581 5.451-8.268 5.451-1.688 0-8.268-2.563-8.268-5.451z"
    />
    <Path
      fill="#4E5E67"
      d="M24.824 42.154c0-2.959 3.114-3.527 6.958-3.527 5.12 0-6.958 7.913-6.958 3.527z"
    />
  </Svg>
);

export default Dog;
