import React from "react";
import Svg, { Path } from "svgs";

import { animalSize } from "../constants/board";

const Hedgehog = (props) => (
  <Svg height={animalSize} width={animalSize} viewBox="0 0 64 64" {...props}>
    <Path
      fill="#8A6859"
      d="M36.069 49.309c2.755 9.864-4.966 8.229-3.237 10.888h7.326c2.098-3.597.279-11.866.279-11.866s-4.775-.48-4.368.978zm-19.243 0c2.755 9.864-4.967 8.229-3.238 10.888h7.326c2.099-3.597.281-11.866.281-11.866s-4.776-.48-4.369.978z"
    />
    <Path
      fill="#AB8A7B"
      d="M22.12 51.112c2.755 9.865-4.967 8.229-3.238 10.888h7.326c2.099-3.597.281-11.865.281-11.865s-4.776-.48-4.369.977zm20.241 0c2.755 9.865-3.97 8.229-2.24 10.888h7.326c2.098-3.597.28-11.865.28-11.865s-5.773-.48-5.366.977z"
    />
    <Path
      fill="#FDE0DA"
      d="M57.934 46.274c-3.37 7.071-12.38 6.787-7.828 8.464-2.775 2.469-13.798-.189-10.711 2.152-2.862.2-13.748-2.152-9.757.127-6.372.205-10.924-2.227-14.204-2.289-9.304-.175-12.785-3.149-12.419-7.523.208-2.504 6.838-2.488 7.874-5.143 0 0 1.278-3.185 3.639-3.272 4-.824 29.634-7.473 43.406 7.484z"
    />
    <Path
      fill="#AB8A7B"
      d="M9.575 47.853c1.313-9 9.715-4.372 7.387.112-1.418 2.731-3.259 3.39-7.387-.112z"
    />
    <Path
      fill="#AB8A7B"
      d="M8.863 43.827c-.88 2.992 4.938 4.429 6.892 9.182 0 0-3.283 1.218-7.191.84-1.036-.252-3.342-.527-3.637-1.597-.935-.076-1.635-1.691-2.006-3.137-.655-1.533-1.683-3.017 5.942-5.288z"
    />
    <Path
      fill="#FDE0DA"
      d="M57.037 46.08s.465 2.484 4.963 2.544c0 0-1.992 3.772-9.914 1.754l4.951-4.298z"
    />
    <Path
      fill="#594640"
      d="M58.717 43.022c2.947-.877 3.262-3.695 3.262-3.695-2.662-.052-4.289-1.484-4.289-1.484 3.104-1.013 3.32-3.541 3.32-3.541-3.996-.731-6.833-2.156-6.833-2.156 1.99-1.104 3.796-3.335 3.796-3.335-6.18-3.377-10.957-2.162-10.957-2.162 1.867-1.485 2.988-4.324 2.988-4.324-7.969-2.096-12.949 1.08-12.949 1.08 1.286-1.712.996-4.324.996-4.324-5.91.392-9.96 3.244-9.96 3.244A411.817 411.817 0 0 0 30.083 18c-13.037.098-15.603 10.739-15.603 10.739-.216-1.688-1.027-3.035-1.027-3.035s-4.445 7.834-3.333 12.285c-.531-.532-1.202-1.428-1.311-2.66 0 0-1.297 5.107 2.08 6.731 0 0 6.474.021 7.996-2.881 0 0-.63 2.471.533 4.799 0 0 2.923-2.545 4.683-4.49 0 0 3.305 4.188 2.994 7.701l4.98-2.162s3.028 3.74 5.976 4.324c0 0 .934-4.324 3.984-4.324 0 0 .622 2.908 3.984 4.324 0 0 1.742-3.715 3.957-4.199 0 0 .565 3.302 3.016 4.199 0 0 1.949-3.038 2.988-3.242 2.844 1.498 5.018 1.043 5.018 1.043-1.976-1.213-2.281-4.13-2.281-4.13z"
    />
    <Path
      fill="#FDE0DA"
      d="M21.019 44.508s-.603-4.03 1.496-6.232c2.097-2.201 4.908-2.379 5.633.479 1.168 4.617-4.348 7.576-7.129 5.753z"
    />
    <Path
      fill="#8A6859"
      d="M22.17 43.085s-.375-2.494.923-3.855c1.298-1.363 3.038-1.472 3.486.297.725 2.854-2.687 4.686-4.409 3.558z"
    />
    <Path
      fill="#AB8A7B"
      d="M20.079 34.052c-1.469-.165-3.612-3.206-1.365-6.771.044 3.61 3.702 7.031 1.365 6.771zm-3.357 3.063c-1.375.552-4.667-1.124-4.318-5.341 1.697 3.172 6.504 4.465 4.318 5.341zm7.406-7.649c-1.317-.682-2.258-4.297 1.088-6.821-1.22 3.39 1.006 7.906-1.088 6.821zm4.441 6.173c-.807-1.259.148-4.869 4.287-5.368-2.719 2.325-3.002 7.366-4.287 5.368zm10.588-.356c-.808-1.258.147-4.868 4.286-5.368-2.718 2.324-3.002 7.366-4.286 5.368zm-8.592-7.788c-.808-1.259.148-4.868 4.287-5.368-2.72 2.325-3.002 7.366-4.287 5.368zm-.08 14.273c-.277-1.475 1.957-4.446 5.972-3.313-3.385 1.102-5.532 5.656-5.972 3.313zm6.372-13.164c-.277-1.475 1.957-4.447 5.973-3.312-3.385 1.103-5.531 5.657-5.973 3.312zm11.872 9.273c.259-1.478 3.392-3.444 6.744-.93-3.549-.197-7.155 3.282-6.744.93zm-.551 5.102c.672-1.338 4.232-2.294 6.719 1.104-3.339-1.239-7.786 1.021-6.719-1.104zm-9.984.503c-.276-1.475 1.957-4.447 5.974-3.312-3.385 1.102-5.532 5.656-5.974 3.312zm7.289-9.647c-.276-1.475 1.957-4.447 5.974-3.312-3.385 1.102-5.532 5.656-5.974 3.312z"
    />
    <Path
      fill="#302C3B"
      d="M3.248 49.335c-1.262-.018-2.191-3.528.422-4.051 3.946-.79.748 4.068-.422 4.051zm12.039-3.168c0 2.705-3.99 2.705-3.99 0-.001-2.696 3.99-2.696 3.99 0z"
    />
    <Path
      fill="#FFF"
      d="M12.834 45.347c0 .677-.997.677-.997 0 0-.675.997-.675.997 0z"
    />
    <Path
      fill="#8A6859"
      d="M4.927 52.252c2.492-.571 4.367.757 6.273-.899-1.5 2.14-3.688.513-6.273.899z"
    />
  </Svg>
);

export default Hedgehog;
