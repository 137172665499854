import React from "react";
import Svg, { Path } from "svgs";

const Logo = ({
  width,
  height,
  textPrimaryColor,
  textSecondaryColor,
  shadowColor,
  style,
}) => (
  <Svg
    title="Matchimals Logo"
    width={width || "100%"}
    height={height || "100%"}
    style={style}
    viewBox="0 0 1224 240"
    xmlns="http://www.w3.org/2000/Svg"
  >
    <Path
      fill={shadowColor || "#2A1A12"}
      d="M492.518,106.594c-2.729-2.729-5.73-4.093-9.005-4.093c-19.647-0.182-35.382,5.776-47.206,17.873
	c-5.003,5.094-8.959,10.733-11.87,16.918c-4.002,8.732-6.003,18.646-6.003,29.743c0.091,11.37,3.093,21.238,9.005,29.606
	c1.455,3.456,3.32,6.685,5.594,9.687l6.685,7.367c2.274,2.274,4.866,4.275,7.777,6.003l7.777,3.957
	c1.273,0.546,2.592,1.046,3.957,1.501c8.095,2.547,16.963,3.365,26.605,2.456c9.641-1.001,18.328-3.365,26.059-7.095
	c2.547-0.819,4.23-2.865,5.048-6.14c1.092-4.002,0.955-8.914-0.409-14.735c-0.637-2.456-1.364-4.639-2.183-6.549
	c-0.182-0.819-0.364-1.592-0.546-2.319c-1.455-5.912-3.547-10.596-6.276-14.053c-1.819-2.001-3.684-3.365-5.594-4.093
	c-2.183-0.728-4.229-0.591-6.14,0.409c-3.729,1.182-8.186,2.092-13.371,2.729c-5.276,0.637-9.596-0.091-12.961-2.183
	c-0.455-0.273-0.91-0.591-1.364-0.955c0-3.729,1.319-7.14,3.957-10.233c3.82-4.366,8.231-7.276,13.234-8.732
	c2.001-0.546,4.048-1.001,6.14-1.364c2.092-0.546,4.184-0.955,6.276-1.228c1.819-0.182,3.274-0.955,4.366-2.319
	c3.093-3.729,3.502-9.187,1.228-16.372c-0.546-1.455-1.046-2.774-1.501-3.957c-0.182-2.092-0.682-4.32-1.501-6.685
	C497.839,114.371,495.247,109.323,492.518,106.594 M190.044,101l-10.233-10.642c-0.728-1.182-3.138-1.956-7.231-2.319
	c-4.093-0.273-8.55-0.227-13.371,0.136c-4.821,0.364-9.55,1.091-14.189,2.183c-4.457,1.001-7.413,2.41-8.868,4.229
	c-3.274,3.638-6.958,9.005-11.051,16.099c-3.456,6.185-7.049,12.688-10.778,19.51c-1.728-2.001-3.456-4.002-5.184-6.003
	c-5.094-6.185-9.596-10.778-13.507-13.78c-0.091-0.091-0.273-0.227-0.546-0.409c-0.273-0.182-0.546-0.318-0.819-0.409l-8.05-6.822
	c-0.364-0.455-0.728-0.819-1.091-1.092c-1.091-0.91-2.047-1.728-2.865-2.456c-1.637-1.455-4.775-2.319-9.414-2.592
	c-4.457-0.273-9.141-0.182-14.053,0.273c-4.821,0.364-9.232,1.001-13.234,1.91c-3.911,1.182-6.23,2.41-6.958,3.684
	c-0.819,1.728-1.001,8.095-0.546,19.101c0.455,10.824,1.273,22.603,2.456,35.336c1.182,12.825,2.592,24.649,4.23,35.473
	c1.637,10.915,3.274,17.327,4.912,19.237c0.091,0.091,0.182,0.182,0.273,0.273c0.091,0,0.182,0.045,0.273,0.136l13.643,7.913
	c0.182,0.182,0.5,0.364,0.955,0.546c0.91,0.273,2.319,0.455,4.229,0.546c2.82,0.273,6.094,0.273,9.823,0
	c3.729-0.091,7.413-0.318,11.051-0.682c3.547-0.455,6.231-1.001,8.05-1.637c1.364-0.546,2.319-4.866,2.865-12.961
	c0.455-7.549,0.637-14.917,0.546-22.102c0.364,0.273,0.682,0.546,0.955,0.819c3.092,2.456,6.049,4.593,8.868,6.412
	c1.364,1.001,2.501,1.728,3.411,2.183c0.273,0.091,0.5,0.227,0.682,0.409c3.002,2.456,5.867,4.548,8.595,6.276
	c2.729,1.91,4.684,2.82,5.867,2.729c1.455-0.182,3.411-1.455,5.867-3.82c2.365-2.274,4.957-5.003,7.777-8.186
	c1.273-1.637,2.547-3.138,3.82-4.502c0.455,1.819,0.91,3.684,1.364,5.594c2.001,8.277,3.684,12.506,5.048,12.688l11.188,8.186
	c0.182,0.182,0.364,0.273,0.546,0.273c3.729,0.455,10.096,0.182,19.101-0.819c8.914-1.091,13.871-2.319,14.871-3.684
	c0.546-1.001,0.955-3.047,1.228-6.14c0.182-3.183,0.182-7.458,0-12.825c-0.364-10.46-1.046-21.92-2.047-34.381
	c-0.273-2.82-0.546-5.594-0.819-8.322l-0.955-9.414h0.136c-0.455-3.365-0.91-6.64-1.364-9.823l-1.364-10.369
	c-1.182-8.277-2.274-13.325-3.274-15.144C190.68,101.546,190.407,101.273,190.044,101 M245.982,101.955
	c-5.457,0.91-10.324,2.183-14.598,3.82c-4.457,1.546-7.049,3.093-7.777,4.639c-1.819,4.002-3.82,10.778-6.003,20.329
	c-2.274,9.459-4.366,19.647-6.276,30.561c-1.546,9.914-2.82,19.419-3.82,28.515c-0.091,1.001-0.182,1.956-0.273,2.865
	c-0.364,3.547-0.591,6.731-0.682,9.55c-0.273,5.275-0.227,9.505,0.136,12.688c0.182,1.455,0.773,2.638,1.774,3.547
	c0.091,0.091,0.227,0.182,0.409,0.273l9.278,7.231c0.182,0.182,0.364,0.364,0.546,0.546c1.273,1.364,5.685,1.774,13.234,1.228
	c7.277-0.364,11.961-0.864,14.053-1.501c0.91-0.182,1.546-0.546,1.91-1.091c0.819-1.001,2.001-3.502,3.547-7.504
	c1.546-4.002,2.865-7.64,3.957-10.915c1.001-2.456,3.411-3.866,7.231-4.229l11.597-0.955c0.455,1,0.955,2.092,1.501,3.274
	c2.001,4.912,3.957,7.868,5.867,8.868l6.549,6.685c0.637,0.819,1.319,1.41,2.047,1.774c0.728,0.364,1.364,0.546,1.91,0.546
	c1.546,0,5.594-0.455,12.143-1.364c6.276-1,11.551-1.956,15.826-2.865c2.638-0.819,4.366-1.819,5.184-3.002
	c1.001-1.273,1.001-3.365,0-6.276c-1.182-3.911-3.638-10.596-7.367-20.056c-3.729-9.55-7.868-19.419-12.416-29.606
	c-4.548-10.369-9.005-19.874-13.371-28.515c-4.639-8.641-8.504-14.144-11.597-16.508c0-0.091-0.046-0.182-0.136-0.273
	c-3.365-5.457-6.185-9.096-8.459-10.915c-2.183-1.728-5.73-2.638-10.642-2.729C256.26,100.591,251.166,101.046,245.982,101.955
	 M300.965,87.766c-0.546,3.638-0.91,7.686-1.091,12.143c-0.091,4.457,0.045,8.868,0.409,13.234c0.364,4.366,0.91,7.549,1.637,9.55
	c0,0.091,0.045,0.182,0.136,0.273c0.182,0.182,0.409,0.364,0.682,0.546l7.095,9.823c0.091,0.182,0.273,0.409,0.546,0.682
	c1,1.001,3.729,1.91,8.186,2.729c3.274,0.637,7.049,1.228,11.324,1.774c-0.091,11.006,0.045,22.011,0.409,33.017
	c0.546,12.825,1.683,20.238,3.411,22.239c0,0.091,0.046,0.182,0.136,0.273l6.685,8.186c0.091,0.182,0.227,0.364,0.409,0.546
	c0.091,0.091,0.182,0.182,0.273,0.273c2.001,1.001,4.821,1.774,8.459,2.319c3.729,0.546,7.458,0.864,11.188,0.955
	c3.82,0.182,7.276,0.046,10.369-0.409c3.092-0.091,4.957-0.637,5.594-1.637c1.637-1.819,3.411-9.096,5.321-21.829
	c1.819-12.916,3.365-26.332,4.639-40.248c5.366,0.273,10.051,0.5,14.053,0.682c4.184,0.091,7.14-0.045,8.868-0.409
	c2.092,0.091,4.002-2.137,5.73-6.685c1.819-4.366,3.138-9.323,3.957-14.871c0.637-5.548,0.637-10.733,0-15.553
	c-0.273-1.819-0.728-3.32-1.364-4.502l-3.957-8.459c-0.455-1.91-1.137-3.365-2.047-4.366c-0.819-1-1.865-1.592-3.138-1.774
	c-7.186-1.001-16.418-1.956-27.696-2.865c-11.278-1.091-22.421-1.91-33.426-2.456c-11.006-0.637-20.738-0.91-29.197-0.819
	c-8.459,0-13.461,0.546-15.008,1.637C302.556,82.309,301.692,84.31,300.965,87.766 M626.633,137.428
	c0.182-6.64,0.318-13.189,0.409-19.647c0.182-11.188-0.046-18.601-0.682-22.239c-0.364-3.002-2.956-5.321-7.777-6.958
	c-4.912-1.728-10.278-2.683-16.099-2.865c-5.639-0.091-10.869,0.5-15.69,1.774c-4.639,1.182-7.049,3.093-7.231,5.73
	c-0.364,4.184-0.546,9.232-0.546,15.144c-0.091,5.73,0,11.87,0.273,18.419c-4.366,0.091-8.55,0.227-12.552,0.409
	c-0.364,0-0.728,0-1.091,0c-0.091-6.64-0.227-12.87-0.409-18.691c-0.091-1.728-0.182-3.365-0.273-4.912
	c-0.273-3.82-0.591-7.277-0.955-10.369c-0.091-1.273-0.773-2.319-2.047-3.138c-1.182-1-3.047-1.865-5.594-2.592
	c-4.912-1.182-10.187-1.683-15.826-1.501c-5.73,0.182-11.006,1.091-15.826,2.729c-1.091,0.455-2.092,0.91-3.002,1.364
	c-2.911,1.455-4.457,3.365-4.639,5.73c-0.273,3.82-0.136,11.233,0.409,22.239c0.546,11.188,1.455,22.739,2.729,34.654
	c1.091,12.097,2.365,22.966,3.82,32.608c1.001,5.821,2.001,10.096,3.002,12.825c1.273,6.913,2.547,10.733,3.82,11.46
	c3.638,1.637,9.641,2.228,18.009,1.774c8.368-0.364,13.007-1.546,13.916-3.547c0.637-1.091,1.228-3.82,1.774-8.186
	c0.546-4.457,1.046-9.914,1.501-16.372c2.729-0.364,5.503-0.728,8.323-1.091c2.82-0.546,5.548-1.091,8.186-1.637
	c0.728,7.186,1.501,13.28,2.319,18.282c0.819,4.912,1.637,7.913,2.456,9.005c0.91,2.001,5.548,3.138,13.916,3.411
	c8.368,0.364,14.28-0.273,17.736-1.91c1.546-0.819,2.865-5.912,3.957-15.281c1.091-9.278,1.956-19.828,2.592-31.653
	c0.182-3.274,0.318-6.549,0.409-9.823L626.633,137.428 M627.861,36.74c-1.728,1.455-2.638,3.365-2.729,5.73v10.369
	c0,0.182,0,0.409,0,0.682c-0.273,1.091-0.318,2.319-0.136,3.684c0.364,3.365,1.91,6.367,4.639,9.005
	c2.456,3.002,5.321,4.866,8.595,5.594c3.183,0.637,5.776,0,7.777-1.91c0.728-0.637,1.319-1.364,1.774-2.183
	c0.091-0.273,0.182-0.546,0.273-0.819c0.364-1.091,0.5-2.365,0.409-3.82l0.955-10.096c0.091-0.91,0.045-2.001-0.137-3.274
	c-0.364-3.365-1.91-6.503-4.639-9.414c-2.82-3.002-5.821-4.775-9.005-5.321C632.363,34.239,629.771,34.83,627.861,36.74
	 M698.943,31.283c-2.638,0.273-5.094,1.683-7.367,4.229c-2.183,2.638-3.502,5.548-3.957,8.732c-0.091,0.364-0.136,0.728-0.136,1.091
	v0.409l-1.774,10.233c0,0.273-0.046,0.591-0.136,0.955c-0.364,1.546-0.455,2.911-0.273,4.093c-3.456-2.82-7.276-4.502-11.461-5.048
	c0.91-1.728,1.501-3.547,1.774-5.457l0.137-0.273l1.364-12.006c0.091-0.364,0.136-0.728,0.136-1.091
	c0.182-1.455,0.182-2.956,0-4.502c-0.182-4.184-1.228-7.549-3.138-10.096s-4.093-3.593-6.549-3.138
	c-2.82,0.455-5.048,2.365-6.685,5.73c-1.091,2.183-1.774,4.639-2.047,7.367c0,0.182,0,0.364,0,0.546l-1.091,11.324
	c-0.091,0.455-0.136,0.91-0.136,1.364c-0.091,1-0.136,2.046-0.136,3.138c0.182,3.365,0.955,6.321,2.319,8.868
	c-3.456,1.364-6.549,3.593-9.278,6.685c-0.909,1.001-1.774,2.137-2.592,3.411v0.136c-2.092,3.093-3.32,6.503-3.684,10.233
	c-0.182,0.91-0.273,1.865-0.273,2.865l-0.682,9.96c0,0.091,0,0.227,0,0.409c-0.091,1.001-0.136,2.001-0.136,3.002
	c0.364,6.822,2.456,10.505,6.276,11.051c-3.638,1.546-5.594,3.547-5.867,6.003c-0.273,2.001-0.364,5.094-0.273,9.277
	c-0.273,0.546-0.455,1.091-0.546,1.637c-0.273,2.274-0.364,6.14-0.273,11.597c0,2.911,0.091,6.367,0.273,10.369
	c0.455,10.915,1.228,22.193,2.319,33.836c0.273,2.729,0.546,5.503,0.819,8.323c0.909,8.459,1.956,16.281,3.138,23.467
	c0.546,3.365,1.091,6.231,1.637,8.595c1.001,4.002,2.047,6.23,3.138,6.685c3.911,1.637,10.505,2.274,19.783,1.91
	c9.278-0.364,14.417-1.546,15.417-3.547c0.182-0.182,0.364-0.455,0.546-0.819c0-0.273,0.091-0.546,0.273-0.819
	c0.182-0.637,0.409-1.41,0.682-2.319l1.501-6.14c0.819-2.456,1.592-6.867,2.319-13.234c0.728-8.459,1.364-18.237,1.91-29.333
	c0.182-6.822,0.364-13.689,0.546-20.602c0-4.366,0-8.732,0-13.098c-0.091-11.46-0.546-20.783-1.364-27.969
	c-0.273-2.729-3.001-4.639-8.186-5.73c-2.82-0.637-5.685-1.091-8.595-1.364c1.728-0.273,3.365-0.5,4.912-0.682
	c4.275-0.455,6.867-2.638,7.777-6.549c0.091-0.455,0.182-0.91,0.273-1.364l1.91-10.233c0.273-1.364,0.409-2.911,0.409-4.639
	c-0.182-3.911-1.137-7.413-2.865-10.505c0.455,0,0.91-0.045,1.364-0.136c2.274-0.182,4.502-1.501,6.685-3.957
	c2.092-2.092,3.502-4.593,4.229-7.504c0.091-0.364,0.182-0.773,0.273-1.228c0-0.091,0.045-0.182,0.136-0.273l2.183-11.733
	c0.091-0.273,0.136-0.5,0.136-0.682c0.546-3.365,0.136-6.231-1.228-8.595C703.263,32.056,701.308,31.101,698.943,31.283
	 M750.106,87.493c-4.548-0.637-9.277-0.955-14.189-0.955c-4.821,0-9.278,0.318-13.371,0.955c-4.002,0.819-6.412,1.819-7.231,3.002
	c-0.273,0.364-0.5,1.091-0.682,2.183c-0.091,0.455-0.182,1.001-0.273,1.637l-2.047,8.459c-0.091,0.364-0.182,0.728-0.273,1.091
	c-0.728,2.911-1.228,8.413-1.501,16.509c-0.273,5.366-0.455,10.915-0.546,16.645l-0.137,12.143c0,0.91,0,1.91,0,3.002
	c0,1.001,0,1.91,0,2.729c0,0.728,0,1.501,0,2.319c0,1.273,0,2.501,0,3.684h0.137l0.273,13.098h-0.136
	c0,1.001,0.045,1.956,0.136,2.865c0.182,4.457,0.409,8.777,0.682,12.961c0.819,10.733,1.91,17.054,3.274,18.964
	c0.273,0.637,1.956,1.182,5.048,1.637c2.82,0.455,6.094,0.728,9.823,0.819c3.547,0.182,7.185,0.227,10.915,0.136
	c3.547-0.091,6.276-0.409,8.186-0.955c0.182-0.091,0.364-0.182,0.546-0.273c0.455-0.637,1-1.91,1.637-3.82l2.456-5.594
	c1.001-1.728,2.001-5.73,3.002-12.006c0.546-4.093,1.046-8.141,1.501-12.143c0.728,0.637,1.501,1.455,2.319,2.456
	c2.456,2.456,5.094,5.048,7.913,7.777c2.82,2.729,5.503,5.094,8.05,7.095c2.638,2.092,4.548,3.138,5.73,3.138
	c1.364,0,3.365-1.091,6.003-3.274c2.638-2.092,5.457-4.548,8.459-7.367c2.911-2.82,5.639-5.548,8.186-8.186
	c2.547-2.729,4.321-4.593,5.321-5.594c0.546,8.459,1.364,16.827,2.456,25.104c1.273,8.186,2.638,12.415,4.093,12.688
	c3.638,0.728,10.005,0.955,19.101,0.682c9.005-0.273,14.007-1.046,15.008-2.319l6.14-8.595l0.136-0.136
	c1.455-1.91,2.456-8.323,3.001-19.237c0.637-10.733,0.955-22.421,0.955-35.064s-0.318-24.422-0.955-35.336
	c-0.455-10.915-1.182-17.509-2.183-19.783c-0.637-1.273-2.956-2.274-6.958-3.002c-4.093-0.637-8.595-1.001-13.507-1.091
	c-4.912,0-9.596,0.318-14.053,0.955c-4.639,0.728-7.777,1.91-9.414,3.547c-3.456,3.365-7.549,8.413-12.279,15.144
	c-4.73,6.822-9.596,13.962-14.599,21.42c-5.003-7.458-9.823-14.598-14.462-21.42c-4.73-6.731-8.959-11.779-12.688-15.144
	C757.564,89.404,754.563,88.221,750.106,87.493 M1069.635,173.72c0.091-5.548-0.228-10.733-0.955-15.553
	c-0.728-4.73-1.955-7.504-3.684-8.323c-1.182-0.455-3.456-0.728-6.822-0.819c-3.729-0.182-7.413-0.318-11.051-0.409
	c-3.729-0.091-7.049-0.318-9.96-0.682c-3.001-0.364-4.639-1.182-4.912-2.456c-0.091-0.637,0.091-3.82,0.546-9.55
	c0.364-5.73,0.728-12.052,1.091-18.964c0.546-7.004,1.091-13.734,1.637-20.192c0.364-6.458,0.591-10.778,0.682-12.961
	c-0.091-1.546-2.456-3.002-7.095-4.366c-4.639-1.182-9.687-2.001-15.144-2.456c-5.548-0.455-10.733-0.455-15.553,0
	c-3.729,0.455-6.139,1.319-7.231,2.592v0.136l-9.005,11.051c-0.364,0.182-0.637,0.455-0.819,0.819
	c-0.182,0.273-0.318,0.591-0.409,0.955c-0.728,3.274-1.637,10.187-2.729,20.738s-1.91,21.648-2.456,33.29
	c-0.819,11.551-1.228,22.239-1.228,32.062c0,2.456,0.045,4.684,0.137,6.685c-0.728-1.91-1.501-3.82-2.32-5.73
	c-3.001-7.458-6.23-15.008-9.687-22.648c-4.548-10.642-9.141-20.374-13.78-29.197c-4.73-8.914-8.595-14.598-11.597-17.054
	c-2.183-1.728-5.821-2.638-10.915-2.729c-4.912,0-9.96,0.455-15.144,1.364c-5.366,0.91-10.233,2.183-14.599,3.82
	c-3.638,1.273-6.003,2.592-7.094,3.957c-0.182,0.182-0.318,0.364-0.409,0.546l-7.095,10.505c-0.091,0.182-0.182,0.364-0.273,0.546
	c-1.819,3.82-3.775,10.414-5.867,19.783c-2.274,9.096-4.32,19.01-6.14,29.743c-1.637,10.551-2.911,20.738-3.82,30.561
	c-1,9.641-1.228,16.872-0.682,21.693c0.182,1.273,0.728,2.365,1.637,3.274c1.182,1.364,5.594,1.819,13.234,1.364
	c7.458-0.455,12.143-1.001,14.053-1.637c0.637-0.182,1.137-0.455,1.501-0.819c0.091-0.091,0.182-0.182,0.273-0.273l7.231-7.64
	c0.182-0.182,0.318-0.364,0.409-0.546c0.909-1.001,2.092-3.547,3.547-7.64c0.909-2.456,1.819-4.639,2.729-6.549
	c0.364-0.091,0.728-0.182,1.091-0.273l11.87-0.955c1.546,0.091,2.865,0.682,3.956,1.774c1.728,1.546,3.593,4.866,5.594,9.96
	c2.183,4.912,4.184,7.868,6.003,8.868c0.637,0.364,1.273,0.546,1.91,0.546c1.637,0.091,5.639-0.364,12.006-1.364
	c6.367-1.001,11.733-2.001,16.099-3.002c2.274-0.637,3.911-1.501,4.912-2.592l8.322-7.231c0.455-0.364,0.819-0.728,1.091-1.091
	c0.091-0.091,0.182-0.182,0.273-0.273c1-1.273,1.046-3.456,0.136-6.549c-0.728-2.274-1.91-5.548-3.547-9.823
	c2.183,1.001,5.548,1.91,10.096,2.729c5.094,0.91,10.596,1.683,16.509,2.319l9.96,0.819c3.82,0.273,7.549,0.546,11.188,0.819
	l10.642,0.409c6.913,0.091,11.551-0.136,13.916-0.682c0.273,0,0.5-0.091,0.682-0.273c0.273-0.091,0.546-0.273,0.819-0.546
	l10.642-8.05c0.273-0.182,0.546-0.364,0.819-0.546c1.092-1.001,2.001-3.183,2.729-6.549c0-0.091,0.045-0.136,0.136-0.136
	C1068.725,183.861,1069.362,178.995,1069.635,173.72 M1171.96,100.728h-0.136c-5.366-5.184-11.597-8.868-18.692-11.051
	c-7.276-2.183-14.78-2.865-22.512-2.047c-4.093,0.455-8.004,1.319-11.733,2.592c-3.456,1.091-6.776,2.592-9.96,4.502
	c-1.637,1-3.183,2.046-4.639,3.138c-1.819,1.455-3.456,3.002-4.912,4.639l-7.095,6.276c-0.455,0.273-0.864,0.591-1.228,0.955
	c-7.549,6.003-12.188,13.734-13.916,23.194c-1.455,8.277-1.228,15.19,0.682,20.738c1.819,5.184,4.457,9.55,7.913,13.098
	c2.638,2.183,5.321,4.048,8.05,5.594c-1.273,0.273-2.456,0.637-3.547,1.091c-2.911,0.91-4.957,2.183-6.14,3.82l-9.414,6.958
	c-0.819,0.455-1.501,0.955-2.047,1.501c-0.91,0.819-1.501,1.819-1.774,3.002c-0.273,0.819-0.364,1.774-0.273,2.865
	c1.273,9.914,6.23,18.601,14.871,26.059c10.096,8.641,22.648,12.961,37.656,12.961c8.914-0.182,16.918-1.956,24.012-5.321
	c0.546-0.273,1.091-0.546,1.637-0.819c1-0.546,2.001-1.137,3.002-1.774l10.369-6.14c1.728-0.91,3.365-1.91,4.912-3.002
	c2.547-1.637,4.912-3.593,7.095-5.867c8.277-7.913,12.279-17.282,12.006-28.105c-0.455-11.006-5.003-20.374-13.643-28.105
	c-4.912-4.457-11.188-8.141-18.828-11.051c0.637-0.182,1.273-0.364,1.91-0.546h0.273c3.456-0.91,6.503-2.274,9.141-4.093
	c0.455-0.364,0.864-0.682,1.228-0.955l13.098-9.414l0.273-0.273c0.182-0.182,0.364-0.318,0.546-0.409
	c1.819-1.455,2.456-3.229,1.91-5.321l-0.137-0.136C1180.555,111.551,1177.236,105.366,1171.96,100.728z"
    />
    <Path
      fill={textSecondaryColor || "#BD852B"}
      d="M451.861,170.036l-0.136-6.549c-1.091,0.728-2.137,1.455-3.138,2.183
	c-8.914,6.276-17.009,13.371-24.285,21.284c-0.273-0.364-0.5-0.728-0.682-1.091c1.455,3.547,3.32,6.867,5.594,9.96l6.822,7.504
	c2.365,2.274,5.048,4.32,8.05,6.14l7.913,3.957c1.273,0.546,2.638,1.046,4.093,1.501c8.186,2.638,17.236,3.502,27.15,2.592
	c9.823-1.001,18.692-3.411,26.605-7.231c2.547-0.819,4.275-2.911,5.185-6.276c1.091-4.093,0.91-9.096-0.546-15.008
	c-0.637-2.547-1.364-4.821-2.183-6.822c1,5.094,1.046,9.459,0.136,13.098c-1,3.456-2.774,5.594-5.321,6.412
	c-8.186,4.002-17.327,6.503-27.423,7.504c-10.096,0.91-19.374,0-27.833-2.729c-1.455-0.455-2.865-0.955-4.229-1.501
	c3.638-6.822,7.413-13.052,11.324-18.691l-1.091-6.276c-0.91,0.91-1.819,1.819-2.729,2.729c-0.091,0-0.136,0.045-0.136,0.136
	c-5.639,5.639-10.778,11.642-15.417,18.009c-3.092-1.819-5.867-3.911-8.322-6.276C438.081,185.226,444.948,177.04,451.861,170.036
	 M499.749,137.019c3.184-3.82,3.593-9.414,1.228-16.781c-0.546-1.455-1.046-2.82-1.501-4.093c0.364,4.275-0.546,7.731-2.729,10.369
	c-1.182,1.455-2.683,2.274-4.502,2.456c-2.274,0.273-4.457,0.682-6.549,1.228c-2.274,0.364-4.457,0.819-6.549,1.364
	c-5.275,1.637-9.869,4.73-13.78,9.278c-4.002,4.457-5.184,9.641-3.547,15.553c0.728,2.001,1.819,3.638,3.274,4.912
	c0-3.82,1.364-7.277,4.093-10.369c3.82-4.457,8.322-7.458,13.507-9.005c2.001-0.546,4.093-1,6.276-1.364
	c2.092-0.546,4.229-0.955,6.412-1.228C497.202,139.157,498.658,138.383,499.749,137.019 M133.696,154.755
	c-0.91,1.182-2.592,3.274-5.048,6.276c-2.547,3.002-5.139,6.094-7.777,9.278c-2.911,3.274-5.594,6.094-8.05,8.459
	c-2.638,2.547-4.684,3.911-6.14,4.093c-0.637,0.091-1.501-0.136-2.592-0.682c0.273,0.182,0.5,0.364,0.682,0.546
	c3.001,2.456,5.912,4.593,8.732,6.412c2.82,1.91,4.821,2.82,6.003,2.729c1.455-0.182,3.456-1.501,6.003-3.957
	c2.365-2.274,5.003-5.048,7.913-8.323c1.273-1.637,2.592-3.183,3.957-4.639C135.834,168.217,134.606,161.486,133.696,154.755
	 M52.245,209.738c0.182,0.182,0.5,0.364,0.955,0.546c0.91,0.273,2.319,0.455,4.229,0.546c2.911,0.273,6.276,0.273,10.096,0
	c3.82-0.091,7.595-0.318,11.324-0.682c3.547-0.455,6.276-1.046,8.186-1.774c1.364-0.546,2.319-4.957,2.865-13.234
	c0.455-7.64,0.637-15.144,0.546-22.512c-2.82-2.456-5.457-4.684-7.913-6.685c-2.82-2.547-4.73-4.275-5.73-5.185
	c0.091,8.277-0.091,16.736-0.546,25.377c-0.546,8.55-1.546,13.098-3.002,13.643c-1.91,0.728-4.684,1.319-8.322,1.774
	c-3.82,0.455-7.686,0.728-11.597,0.819c-1.273,0.091-2.501,0.182-3.684,0.273c-2.456,0-4.684-0.091-6.685-0.273
	c-2.456-0.182-4.048-0.455-4.775-0.819L52.245,209.738 M81.169,96.089c1.273,1.455,2.592,3.002,3.957,4.639
	c5.457,6.458,11.051,13.28,16.781,20.465c0.546-1.091,1.137-2.138,1.774-3.138c-1.728-2.092-3.456-4.138-5.185-6.14
	c-5.275-6.367-9.869-11.051-13.78-14.053c-0.182-0.182-0.409-0.364-0.682-0.546c-0.273-0.182-0.546-0.318-0.819-0.409l-8.186-6.958
	C76.94,91.677,78.986,93.724,81.169,96.089 M181.858,88.994c-0.182-0.273-0.455-0.546-0.819-0.819L170.67,77.26
	c0.819,1.455,1.637,4.593,2.456,9.414c0.273,1.91,0.591,4.093,0.955,6.549c-13.28,2.274-26.195,6.276-38.747,12.006
	c-1.273,0.546-2.501,1.091-3.684,1.637l5.594,3.411c11.369-3.002,24.149-5.094,38.338-6.276c0.455,3.365,0.91,6.822,1.364,10.369
	c-10.278,0.728-20.374,2.41-30.288,5.048c-1.273,0.273-2.547,0.591-3.82,0.955l4.912,4.093c9.368-0.728,19.419-0.819,30.152-0.273
	c0.273,2.911,0.546,5.867,0.819,8.868c1.091,12.916,1.819,24.876,2.183,35.882c0.273,11.188-0.182,17.827-1.364,19.919
	c-1.001,1.364-6.185,2.638-15.554,3.82c-4.821,0.546-8.868,0.91-12.143,1.091c-3.365,0.091-6.003,0.046-7.913-0.136l11.324,8.459
	c0.182,0.182,0.364,0.273,0.546,0.273c3.82,0.455,10.324,0.136,19.51-0.955c9.187-1.091,14.28-2.319,15.281-3.684
	c0.546-1.001,0.955-3.092,1.228-6.276c0.182-3.274,0.182-7.64,0-13.098c-0.364-10.733-1.046-22.421-2.047-35.064
	c-0.273-2.911-0.546-5.776-0.819-8.595l-1.092-9.55h0.136c-0.455-3.456-0.91-6.822-1.364-10.096l-1.364-10.505
	C184.086,96.089,182.949,90.904,181.858,88.994 M213.647,209.329c-1.91,0.091-3.638,0.182-5.184,0.273
	c-4.275,0-7.004-0.409-8.186-1.228l9.55,7.504c0.182,0.182,0.364,0.364,0.546,0.546c1.273,1.364,5.776,1.774,13.507,1.228
	c7.458-0.364,12.234-0.864,14.326-1.501c0.91-0.182,1.546-0.591,1.91-1.228c0.91-1.001,2.137-3.547,3.684-7.64
	c1.546-4.093,2.911-7.777,4.093-11.051c1.001-2.547,3.456-4.002,7.367-4.366l11.87-0.955c-1.637-3.456-3.183-5.867-4.639-7.231
	c-1.091-1.091-2.41-1.728-3.957-1.91l-12.688,1.091c-4.002,0.364-6.503,1.865-7.504,4.502c-1.182,3.365-2.592,7.14-4.229,11.324
	c-1.546,4.184-2.774,6.776-3.684,7.777c-0.455,0.637-1.137,1.091-2.047,1.364C226.29,208.465,221.378,208.965,213.647,209.329
	 M273.541,101.955c-0.091-0.091-0.182-0.182-0.273-0.273c1.091,1.91,2.228,4.002,3.411,6.276
	c4.639,9.005,9.323,18.919,14.053,29.743c4.73,10.733,9.05,21.102,12.961,31.107c3.911,9.914,6.549,16.918,7.913,21.011
	c1.001,3.092,1.001,5.275,0,6.549c-1.001,1.273-2.82,2.319-5.457,3.138c-4.548,1-10.096,2.047-16.645,3.138
	c-5.639,0.728-9.505,1.182-11.597,1.364c-0.546,0-0.955,0-1.228,0c-0.546,0-1.182-0.182-1.91-0.546l6.549,6.685
	c0.637,0.819,1.319,1.41,2.047,1.774c0.728,0.364,1.364,0.546,1.91,0.546c1.637,0,5.776-0.455,12.415-1.364
	c6.458-1,11.87-2.001,16.236-3.002c2.638-0.819,4.411-1.819,5.321-3.002c1-1.273,1-3.411,0-6.412
	c-1.273-4.002-3.82-10.824-7.64-20.465c-3.82-9.732-8.05-19.828-12.688-30.288c-4.639-10.551-9.187-20.238-13.643-29.06
	C280.545,110.051,276.634,104.411,273.541,101.955 M258.124,150.799c0.091-1.091-0.728-3.047-2.456-5.867
	c-0.91-1.364-1.956-2.865-3.138-4.502c-3.82-5.548-6.594-7.14-8.322-4.775c-1.637,2.001-3.093,5.184-4.366,9.55
	c-1.364,4.366-1.728,7.186-1.091,8.459c0.91,2.274,4.366,3.047,10.369,2.319C254.85,155.165,257.851,153.437,258.124,150.799
	 M323.885,126.514c0-3.274,0-6.594,0-9.96c-7.64-0.728-14.144-1.592-19.51-2.592c-0.819-0.182-1.592-0.318-2.319-0.409
	c-2.911-0.728-4.866-1.501-5.867-2.319l7.231,9.96c0.091,0.182,0.273,0.409,0.546,0.682c1.001,1.001,3.775,1.956,8.322,2.865
	C315.654,125.377,319.52,125.968,323.885,126.514 M376.003,119.692c-1.273,14.553-2.911,28.606-4.912,42.158
	c-2.001,13.371-3.82,20.965-5.457,22.785c-0.728,1.001-2.683,1.637-5.867,1.91c-3.274,0.455-6.913,0.591-10.915,0.409
	c-3.911-0.091-7.822-0.5-11.733-1.228c-1.546-0.182-2.911-0.409-4.093-0.682c-2.001-0.455-3.593-1.001-4.775-1.637
	c-0.091-0.091-0.182-0.136-0.273-0.136l6.822,8.322c0.091,0.182,0.227,0.364,0.409,0.546c0.091,0.091,0.182,0.182,0.273,0.273
	c2.001,1.001,4.866,1.774,8.595,2.319c3.82,0.637,7.64,1,11.461,1.091c3.911,0.182,7.458,0.046,10.642-0.409
	c3.092-0.182,5.003-0.773,5.73-1.774c1.637-1.819,3.411-9.232,5.321-22.239c1.91-13.189,3.502-26.878,4.775-41.067
	c5.457,0.273,10.233,0.5,14.326,0.682c4.275,0.091,7.322-0.045,9.141-0.409c2.092,0.091,4.048-2.228,5.867-6.958
	c1.819-4.457,3.138-9.505,3.957-15.144c0.637-5.639,0.637-10.915,0-15.826c-0.273-1.91-0.728-3.456-1.364-4.639l-4.093-8.595
	c0.091,0.546,0.182,1.091,0.273,1.637c0.182,1.364,0.318,2.729,0.409,4.093c0.182,2.274,0.227,4.639,0.136,7.095
	c-0.182,1.637-0.364,3.32-0.546,5.048c-0.819,5.73-2.138,10.915-3.957,15.553c-1.91,4.821-3.957,7.186-6.14,7.095
	c-1.819,0.364-4.912,0.5-9.277,0.409C386.463,120.192,381.552,119.965,376.003,119.692 M626.36,140.839
	c-0.728,12.279-1.637,23.33-2.729,33.153c-1.182,9.823-2.547,15.144-4.093,15.963c-3.638,1.728-9.869,2.41-18.691,2.047
	c-8.732-0.364-13.598-1.546-14.599-3.547c-0.91-1.091-1.774-4.275-2.592-9.55c-0.819-5.184-1.592-11.506-2.319-18.964
	c-2.82,0.546-5.685,1.091-8.595,1.637c-3.002,0.364-5.958,0.728-8.868,1.091c-0.455,6.731-0.955,12.461-1.501,17.191
	c-0.546,4.639-1.137,7.504-1.774,8.595c-1,2.001-5.867,3.229-14.598,3.684c-8.823,0.455-15.144-0.182-18.964-1.91
	c-0.637-0.273-1.228-1.228-1.774-2.865c1.273,7.095,2.592,11.006,3.956,11.733c3.729,1.637,9.869,2.228,18.419,1.774
	c8.55-0.364,13.28-1.546,14.189-3.547c0.637-1.091,1.228-3.866,1.774-8.323c0.546-4.639,1.046-10.233,1.501-16.781
	c2.82-0.364,5.685-0.728,8.595-1.091c2.82-0.546,5.594-1.091,8.322-1.637c0.728,7.277,1.501,13.462,2.319,18.555
	c0.819,5.094,1.683,8.186,2.592,9.278c0.909,2.001,5.639,3.138,14.189,3.411c8.55,0.364,14.598-0.273,18.146-1.91
	c1.546-0.819,2.865-6.003,3.957-15.553c1.091-9.55,2.001-20.329,2.729-32.335C626.133,147.57,626.269,144.205,626.36,140.839
	 M630.589,40.014c-1.273-1.273-2.274-2.592-3.001-3.957c-1.001-1.819-1.637-3.684-1.91-5.594c-0.08-0.478-0.125-0.978-0.136-1.501
	v10.096c-0.091,0.182-0.137,0.409-0.137,0.682c-0.273,1.091-0.318,2.319-0.136,3.684c0.455,3.456,2.047,6.549,4.775,9.277
	c2.547,3.002,5.503,4.866,8.868,5.594c3.184,0.728,5.821,0.091,7.913-1.91c0.728-0.637,1.319-1.364,1.774-2.183
	c0.091-0.273,0.182-0.546,0.273-0.819c0.364-1.182,0.5-2.501,0.409-3.957l0.955-10.233c-0.273,1.91-1.091,3.456-2.456,4.639
	c-1.091,1.001-2.274,1.683-3.547,2.047c-1.364,0.273-2.865,0.227-4.502-0.136C636.274,45.017,633.227,43.107,630.589,40.014
	 M643.96,108.368c-0.273,0.546-0.455,1.091-0.546,1.637c-0.273,2.365-0.364,6.321-0.273,11.87c0,3.002,0.091,6.549,0.273,10.642
	c0.455,11.097,1.273,22.603,2.456,34.518c0.273,2.82,0.546,5.639,0.819,8.459c0.91,8.641,1.956,16.645,3.138,24.012
	c0.546,3.456,1.137,6.367,1.774,8.732c1.001,4.093,2.047,6.412,3.138,6.958c4.002,1.637,10.733,2.274,20.192,1.91
	c9.46-0.364,14.735-1.592,15.826-3.684c0.182-0.182,0.364-0.455,0.546-0.819c0-0.273,0.091-0.546,0.273-0.819
	c0.182-0.637,0.409-1.41,0.682-2.319l1.501-6.276c-0.273,0.819-0.5,1.455-0.682,1.91h-0.136c-1.182,2.092-6.594,3.32-16.236,3.684
	c-9.732,0.364-16.645-0.273-20.738-1.91c-1.819-0.819-3.502-6.185-5.048-16.099c-0.819-4.912-1.546-10.142-2.183-15.69
	c-0.728-5.548-1.364-11.415-1.91-17.6c-0.819-8.732-1.501-17.191-2.047-25.377c-0.182-3.365-0.364-6.731-0.546-10.096
	C644.051,116.645,643.96,112.097,643.96,108.368 M645.052,72.622c-0.182-0.91-0.318-1.91-0.409-3.002c0-0.637,0-1.228,0-1.774
	l-0.682,10.233c0,0.091,0,0.227,0,0.409c-0.091,1.001-0.136,2.001-0.136,3.002c0.364,7.004,2.501,10.778,6.412,11.324
	c0.819-0.364,1.728-0.682,2.729-0.955c5.366-1.637,11.37-2.547,18.009-2.729c3.183-0.091,6.23,0,9.141,0.273
	c1.728-0.273,3.411-0.5,5.048-0.682c4.366-0.455,7.004-2.683,7.913-6.685c0.091-0.455,0.182-0.91,0.273-1.364l1.91-10.505
	c-0.455,1.637-1.137,3.002-2.047,4.093c-1.455,1.546-3.502,2.456-6.14,2.729c-6.003,0.728-12.37,1.774-19.101,3.138
	c-6.913,1.455-12.461,1.819-16.645,1.091C647.962,80.763,645.87,77.898,645.052,72.622 M696.351,41.924
	c-2.729,0.364-4.775-0.682-6.14-3.138c-0.364-0.546-0.637-1.137-0.819-1.774c-0.496-1.404-0.632-2.996-0.409-4.775l-1.637,9.96
	c0,0.273-0.045,0.591-0.136,0.955c-0.364,1.546-0.455,2.956-0.273,4.229c0.182,0.273,0.409,0.5,0.682,0.682
	c2.183,2.001,3.911,4.229,5.184,6.685c0.455,0,0.91-0.045,1.364-0.136c2.365-0.182,4.639-1.546,6.822-4.093
	c2.183-2.092,3.638-4.639,4.366-7.64c0.091-0.364,0.182-0.773,0.273-1.228c0-0.091,0.045-0.182,0.136-0.273l2.183-12.006
	c-0.455,2.274-1.319,4.32-2.592,6.14c-0.546,0.819-1.182,1.592-1.91,2.319C701.171,40.378,698.807,41.742,696.351,41.924
	 M676.977,36.603l0.136-0.273l1.501-12.279c-0.273,1.91-0.864,3.729-1.774,5.457c-0.091,0-0.136,0.045-0.136,0.136
	c-1.728,3.547-3.866,5.503-6.412,5.867c-2.729,0.455-5.094-0.546-7.095-3.002c-1.546-2.001-2.547-4.411-3.002-7.231
	c-0.182-1.091-0.318-2.228-0.409-3.411c0-0.953,0-1.862,0-2.729l-0.955,11.188c-0.091,0.455-0.136,0.91-0.136,1.364
	c-0.091,1.091-0.137,2.183-0.136,3.274c0.182,3.456,0.955,6.458,2.319,9.005c2.638-1.091,5.457-1.774,8.459-2.047
	c2.001-0.182,3.957-0.091,5.867,0.273C676.113,40.469,676.704,38.605,676.977,36.603 M799.768,175.357
	c3.002-2.82,5.821-5.594,8.459-8.322c2.547-2.729,4.32-4.639,5.321-5.73c0.546,8.641,1.41,17.191,2.592,25.65
	c1.273,8.368,2.638,12.688,4.093,12.961c3.729,0.728,10.233,0.955,19.51,0.682c9.187-0.273,14.326-1.046,15.417-2.319l6.276-8.732
	c-0.637,0.546-2.092,1.001-4.366,1.364c-2.638,0.455-6.412,0.773-11.324,0.955c-9.459,0.273-16.099,0-19.92-0.819
	c-1.546-0.273-2.956-4.684-4.229-13.234c-1.273-8.732-2.183-17.509-2.729-26.332c-1,1.091-2.82,3.047-5.457,5.867
	c-0.637,0.637-1.228,1.273-1.774,1.91H811.5c-2.183,2.183-4.411,4.411-6.685,6.685c-3.093,3.002-6.049,5.594-8.868,7.777
	c-2.82,2.274-4.912,3.411-6.276,3.411c-1.273,0-3.274-1.091-6.003-3.274c-2.729-2.092-5.548-4.548-8.459-7.367
	c-3.002-2.911-5.73-5.685-8.186-8.322c-2.638-2.729-4.457-4.593-5.457-5.594c-0.273,4.184-0.637,8.459-1.091,12.825
	c0.728,0.637,1.546,1.455,2.456,2.456c2.456,2.547,5.139,5.23,8.05,8.05c2.82,2.729,5.548,5.139,8.186,7.231
	c2.729,2.092,4.684,3.138,5.867,3.138c1.364,0,3.411-1.091,6.14-3.274C793.901,180.814,796.766,178.268,799.768,175.357
	 M759.929,108.914l3.138-5.594c-16.827,1.091-32.881,4.73-48.161,10.915c0.091-6.094,0.273-11.915,0.546-17.464
	c0.091-3.274,0.227-6.094,0.409-8.459c0.182-2.82,0.409-5.139,0.682-6.958l-2.047,8.595c-0.091,0.364-0.182,0.773-0.273,1.228
	c-0.728,2.911-1.228,8.504-1.501,16.781c-0.273,5.457-0.455,11.142-0.546,17.054l-0.136,12.416c0,0.91,0,1.91,0,3.002
	c0,1,0,1.956,0,2.865c0,0.728,0,1.501,0,2.319c0,1.273,0,2.501,0,3.684h0.136l0.273,13.507h-0.136c0,1.001,0.045,1.956,0.136,2.865
	c0.182,4.548,0.409,8.959,0.682,13.234c0.819,10.915,1.91,17.373,3.274,19.374c0.364,0.637,2.092,1.182,5.185,1.637
	c2.911,0.455,6.276,0.728,10.096,0.819c3.638,0.182,7.322,0.227,11.051,0.136c3.638-0.091,6.458-0.409,8.459-0.955
	c0.182-0.091,0.364-0.182,0.546-0.273c0.455-0.637,1-1.91,1.637-3.82l2.592-5.73c-0.364,0.546-0.728,0.864-1.092,0.955
	c-0.182,0.091-0.318,0.136-0.409,0.136c-2.001,0.546-4.73,0.864-8.186,0.955c-3.82,0.091-7.64,0.045-11.461-0.136
	c-3.911-0.091-7.367-0.409-10.369-0.955c-3.093-0.455-4.866-1.001-5.321-1.637c-0.91-1.364-1.728-4.957-2.456-10.778
	c-0.273-2.638-0.546-5.685-0.819-9.141c-0.364-5.275-0.637-10.778-0.819-16.509c15.099-11.188,29.424-20.01,42.977-26.468
	l2.183-6.003c-1.273,0.273-2.547,0.591-3.82,0.955c-14.644,3.911-28.469,9.823-41.476,17.736h-0.136c0-1.273,0-2.547,0-3.82
	c0-0.819,0-1.592,0-2.319c0-1,0-2.001,0-3.002c0-1.091,0-2.138,0-3.138C730.96,118.737,746.013,112.734,759.929,108.914
	 M937.976,189.41l-12.552,0.955c-4.002,0.455-6.503,2.001-7.504,4.639c-0.546,1.364-1.091,2.865-1.637,4.502
	c0.364-0.091,0.728-0.182,1.092-0.273l12.143-0.955c1.637,0.091,3.002,0.682,4.093,1.774c1.728,1.637,3.638,5.048,5.73,10.233
	c2.183,5.003,4.229,8.004,6.14,9.005c0.637,0.364,1.273,0.546,1.91,0.546c1.637,0.091,5.73-0.364,12.279-1.364
	c6.458-1.001,11.915-2.001,16.372-3.002c2.365-0.728,4.048-1.637,5.048-2.729l8.459-7.367c-1,0.637-2.274,1.182-3.82,1.637
	c-1.273,0.273-2.547,0.591-3.82,0.955c-3.82,0.728-8.141,1.455-12.961,2.183c-6.731,1.001-10.96,1.455-12.688,1.364
	c-0.637,0-1.273-0.182-1.91-0.546c-2.001-1.001-4.093-4.093-6.276-9.277c-2.183-5.276-4.184-8.777-6.003-10.505
	C940.977,190.092,939.613,189.501,937.976,189.41 M882.311,157.348c1.91-11.188,4.048-21.602,6.412-31.243
	c1.182-5.185,2.319-9.55,3.411-13.098c0.964-3.067,1.873-5.569,2.729-7.504l-6.958,10.369c-0.091,0.182-0.182,0.364-0.273,0.546
	c-1.91,3.911-3.911,10.642-6.003,20.192c-2.365,9.369-4.457,19.51-6.276,30.425c-1.728,10.824-3.047,21.238-3.957,31.243
	c-1,9.823-1.228,17.191-0.682,22.102c0.182,1.364,0.728,2.501,1.637,3.411c1.273,1.364,5.776,1.819,13.507,1.364
	c7.64-0.455,12.461-1.001,14.462-1.637c0.637-0.182,1.137-0.455,1.501-0.819c0.091-0.091,0.182-0.182,0.273-0.273l7.367-7.913
	c-0.364,0.364-0.864,0.637-1.501,0.819c-0.637,0.182-1.501,0.364-2.592,0.546c-2.729,0.455-6.822,0.819-12.279,1.091
	c-7.913,0.455-12.506,0-13.78-1.364c-1.001-0.91-1.592-2.092-1.774-3.547c-0.546-5.003-0.318-12.552,0.682-22.648
	C879.218,179.132,880.582,168.444,882.311,157.348 M918.329,161.304c0.728,2.092,3.82,2.865,9.278,2.319
	c0.273-0.091,0.591-0.136,0.955-0.136c5.73-0.728,8.777-2.456,9.141-5.185c0.091-1.546-1.774-5.003-5.594-10.369
	c-3.911-5.548-6.731-7.14-8.459-4.775c-1.546,2.001-3.002,5.184-4.366,9.55v0.136C917.92,157.12,917.602,159.94,918.329,161.304
	 M1062.949,192.957l10.778-8.186c-0.182,0-0.364,0-0.546,0c-1.546,0.364-4.048,0.591-7.504,0.682c-2.001,0-4.366,0-7.094,0
	c-5.821-9.005-10.733-17.736-14.735-26.195l-6.003-2.183c0.273,1.273,0.591,2.501,0.955,3.684
	c2.274,8.368,5.184,16.463,8.732,24.285c-3.82-0.273-7.731-0.546-11.733-0.819c-4.002-9.368-7.185-18.328-9.55-26.878l-5.73-3.138
	c0.091,1.273,0.227,2.547,0.409,3.82c0.728,8.641,2.183,17.1,4.366,25.377c-6.185-0.637-11.915-1.41-17.191-2.319
	c-7.367-1.364-11.915-3.093-13.643-5.184c-1.546-1.91-2.319-7.959-2.319-18.146c0-10.278,0.409-21.466,1.228-33.563
	c0.637-12.279,1.546-23.921,2.729-34.927c0.546-5.457,1.046-9.96,1.501-13.507c0.455-3.729,0.864-6.458,1.228-8.186
	c0.066-0.329,0.157-0.602,0.273-0.819l-9.005,10.915c-0.364,0.273-0.637,0.591-0.819,0.955c-0.182,0.273-0.318,0.591-0.409,0.955
	c-0.728,3.365-1.637,10.414-2.729,21.147c-1.091,10.733-1.955,22.057-2.592,33.972c-0.819,11.824-1.228,22.739-1.228,32.744
	c0,2.547,0.045,4.821,0.136,6.822c0.273,0.728,0.546,1.41,0.819,2.047c1.637,4.093,3.047,7.731,4.229,10.915
	c2.274,1.091,5.73,2.047,10.369,2.865c5.184,0.91,10.778,1.683,16.781,2.319l10.233,0.819c3.911,0.273,7.731,0.546,11.46,0.819
	l10.778,0.409c7.094,0.091,11.824-0.136,14.189-0.682c0.273,0,0.546-0.091,0.819-0.273
	C1062.404,193.412,1062.676,193.23,1062.949,192.957 M1084.642,173.174c-0.819,0.455-1.501,0.955-2.047,1.501
	c-1.001,0.91-1.637,1.956-1.91,3.138c-0.273,0.819-0.364,1.774-0.273,2.865c1.364,10.187,6.458,19.055,15.281,26.605
	c10.278,8.823,23.103,13.234,38.474,13.234c9.096-0.182,17.236-1.956,24.422-5.321c0.546-0.364,1.137-0.682,1.774-0.955
	c1-0.546,2.001-1.137,3.001-1.774l10.642-6.276c-1.728,0.819-3.502,1.592-5.321,2.319c-6.276,2.365-13.189,3.593-20.738,3.684
	c-15.735,0-28.879-4.502-39.43-13.507c-9.096-7.731-14.326-16.827-15.69-27.287c-0.091-0.364-0.091-0.728,0-1.091
	c-0.091-1.637,0.364-3.047,1.364-4.229L1084.642,173.174 M1170.323,119.146h-0.273c-4.093,0.455-7.095,0.637-9.005,0.546h-0.136
	c-0.819,0-1.546-0.318-2.183-0.955c-0.728-0.637-1.137-1.228-1.228-1.774c0.182-1.455-0.546-3.002-2.183-4.639
	c-2.365-2.274-5.23-2.865-8.595-1.774c-1.455,0.455-2.956,1.182-4.502,2.183v-0.136c-3.547,2.001-4.502,4.048-2.865,6.14
	c0.91,1.546,3.593,3.093,8.05,4.639c0.91,0.273,2.001,0.5,3.274,0.682h0.136c0.819,0.091,1.728,0.318,2.729,0.682h0.136
	c4.184,1.091,8.05,2.319,11.597,3.684c0.637-0.182,1.319-0.364,2.047-0.546h0.273c3.547-0.91,6.64-2.319,9.278-4.229
	c0.455-0.364,0.864-0.682,1.228-0.955l13.37-9.55c-2.638,1.728-5.73,3.047-9.277,3.957h-0.273c-0.91,0.273-1.774,0.546-2.592,0.819
	C1176.235,118.555,1173.234,118.964,1170.323,119.146 M1099.65,109.869c1.182-6.276,3.593-11.824,7.231-16.645
	c0.91-1.273,1.91-2.456,3.002-3.547l-7.231,6.412c-0.455,0.273-0.864,0.591-1.228,0.955c-7.731,6.185-12.506,14.098-14.326,23.74
	c-1.455,8.459-1.228,15.508,0.682,21.147c1.91,5.366,4.639,9.823,8.186,13.371c2.638,2.274,5.366,4.184,8.186,5.73
	c1.546-0.364,3.229-0.591,5.048-0.682c3.001-0.273,6.003-0.318,9.005-0.136c4.639,0.455,8.868,1.455,12.688,3.002h0.136
	c3.001,1.182,4.684,2.592,5.048,4.229c0,0.091,0.045,0.227,0.136,0.409c0.091,0.364,0.228,0.773,0.409,1.228
	c0.455,1.819,1.865,3.411,4.229,4.775h0.136c2.456,1.273,5.366,1.364,8.732,0.273c2.001-0.637,3.365-1.592,4.093-2.865
	c0.728-1.273,0.773-2.911,0.136-4.912c-0.637-1.91-2.638-3.502-6.003-4.775c-6.913-2.456-13.78-4.502-20.602-6.14
	c-6.64-1.819-12.825-5.185-18.555-10.096c-3.638-3.638-6.458-8.232-8.459-13.78C1098.331,125.831,1098.104,118.6,1099.65,109.869z"
    />
    <Path
      fill={textPrimaryColor || "#FFD669"}
      d="M490.062,93.906c-2.82-2.82-5.912-4.229-9.277-4.229c-20.01-0.182-36.064,5.912-48.161,18.282
	c-5.094,5.184-9.141,10.96-12.143,17.327c-4.093,8.914-6.14,19.01-6.14,30.288c0.091,11.642,3.184,21.738,9.278,30.288
	c0.182,0.364,0.409,0.728,0.682,1.091c7.277-7.913,15.372-15.008,24.285-21.284c1.001-0.728,2.047-1.455,3.138-2.183l0.136,6.549
	c-6.913,7.004-13.78,15.19-20.602,24.558c2.456,2.365,5.23,4.457,8.322,6.276c4.639-6.367,9.778-12.37,15.417-18.009
	c0-0.091,0.045-0.136,0.136-0.136c0.91-0.91,1.819-1.819,2.729-2.729l1.091,6.276c-3.911,5.639-7.686,11.87-11.324,18.691
	c1.364,0.546,2.774,1.046,4.229,1.501c8.459,2.729,17.737,3.638,27.833,2.729c10.096-1.001,19.237-3.502,27.423-7.504
	c2.547-0.819,4.32-2.956,5.321-6.412c0.91-3.638,0.864-8.004-0.136-13.098c-0.182-0.819-0.364-1.592-0.546-2.319
	c-1.455-6.003-3.593-10.778-6.412-14.326c-1.819-2.092-3.729-3.502-5.73-4.229c-2.183-0.728-4.275-0.591-6.276,0.409
	c-3.82,1.273-8.368,2.228-13.643,2.865c-5.366,0.637-9.778-0.136-13.234-2.319c-0.455-0.273-0.91-0.591-1.364-0.955
	c-1.455-1.273-2.547-2.911-3.274-4.912c-1.637-5.912-0.455-11.097,3.547-15.553c3.911-4.548,8.504-7.64,13.78-9.278
	c2.092-0.546,4.275-1.001,6.549-1.364c2.092-0.546,4.275-0.955,6.549-1.228c1.819-0.182,3.32-1,4.502-2.456
	c2.183-2.638,3.093-6.094,2.729-10.369c-0.182-2.092-0.682-4.366-1.501-6.822C495.429,101.865,492.791,96.725,490.062,93.906
	 M180.902,168.945c-0.364-11.006-1.091-22.966-2.183-35.882c-0.273-3.002-0.546-5.958-0.819-8.868
	c-10.733-0.546-20.783-0.455-30.152,0.273l-4.912-4.093c1.273-0.364,2.547-0.682,3.82-0.955c9.914-2.638,20.01-4.32,30.288-5.048
	c-0.455-3.547-0.91-7.004-1.364-10.369c-14.189,1.182-26.968,3.274-38.338,6.276l-5.594-3.411c1.182-0.546,2.41-1.091,3.684-1.637
	c12.552-5.73,25.468-9.732,38.747-12.006c-0.364-2.456-0.682-4.639-0.955-6.549c-0.819-4.821-1.637-7.959-2.456-9.414
	c-0.728-1.182-3.183-1.956-7.367-2.319c-4.184-0.273-8.777-0.227-13.78,0.136c-4.912,0.364-9.732,1.091-14.462,2.183
	c-4.548,1.091-7.549,2.547-9.005,4.366c-3.365,3.729-7.14,9.232-11.324,16.509c-3.547,6.276-7.231,12.916-11.051,19.919
	c-0.637,1.001-1.228,2.047-1.774,3.138c-5.73-7.186-11.324-14.007-16.781-20.465c-1.364-1.637-2.683-3.183-3.957-4.639
	c-2.183-2.365-4.23-4.411-6.14-6.14c-0.364-0.455-0.728-0.819-1.092-1.091c-1.091-0.91-2.092-1.728-3.001-2.456
	c-1.637-1.546-4.821-2.456-9.55-2.729c-4.548-0.273-9.323-0.182-14.326,0.273c-4.912,0.364-9.414,1.046-13.507,2.047
	c-4.002,1.182-6.367,2.41-7.095,3.684c-0.91,1.819-1.137,8.322-0.682,19.51c0.546,11.097,1.41,23.148,2.592,36.155
	c1.182,13.098,2.638,25.149,4.366,36.155c1.637,11.188,3.274,17.736,4.912,19.647c0.091,0.091,0.182,0.182,0.273,0.273
	c0.091,0,0.182,0.046,0.273,0.136c0.728,0.364,2.319,0.637,4.775,0.819c2.001,0.182,4.229,0.273,6.685,0.273
	c1.182-0.091,2.41-0.182,3.684-0.273c3.911-0.091,7.777-0.364,11.597-0.819c3.638-0.455,6.412-1.046,8.322-1.774
	c1.455-0.546,2.456-5.094,3.002-13.643c0.455-8.641,0.637-17.1,0.546-25.377c1.001,0.91,2.911,2.638,5.73,5.185
	c2.456,2.001,5.093,4.229,7.913,6.685c0.364,0.273,0.728,0.546,1.091,0.819c3.093,2.547,6.094,4.73,9.005,6.549
	c1.364,1.001,2.547,1.728,3.547,2.183c1.091,0.546,1.956,0.773,2.592,0.682c1.455-0.182,3.502-1.546,6.14-4.093
	c2.456-2.365,5.139-5.184,8.05-8.459c2.638-3.183,5.23-6.276,7.777-9.278c2.456-3.002,4.138-5.094,5.048-6.276
	c0.91,6.731,2.137,13.462,3.684,20.192c0.455,1.91,0.91,3.82,1.364,5.73c2.001,8.459,3.729,12.779,5.185,12.961
	c1.91,0.182,4.548,0.227,7.913,0.136c3.274-0.182,7.322-0.546,12.143-1.091c9.369-1.182,14.553-2.456,15.554-3.82
	C180.721,186.772,181.175,180.132,180.902,168.945 M223.333,93.087c-4.548,1.546-7.186,3.138-7.913,4.775
	c-1.91,4.002-3.957,10.915-6.14,20.738c-2.365,9.641-4.502,20.056-6.412,31.243c-1.637,10.096-2.956,19.783-3.957,29.06
	c-0.091,1.001-0.182,1.956-0.273,2.865c-0.364,3.638-0.591,6.913-0.682,9.823c-0.273,5.366-0.227,9.687,0.136,12.961
	c0.182,1.455,0.773,2.638,1.774,3.547c0.091,0.091,0.227,0.182,0.409,0.273c1.182,0.819,3.911,1.228,8.186,1.228
	c1.546-0.091,3.274-0.182,5.184-0.273c7.731-0.364,12.643-0.864,14.735-1.501c0.909-0.273,1.592-0.728,2.047-1.364
	c0.909-1.001,2.138-3.593,3.684-7.777c1.637-4.184,3.047-7.959,4.229-11.324c1.001-2.638,3.502-4.139,7.504-4.502l12.688-1.091
	c1.546,0.182,2.865,0.819,3.957,1.91c1.455,1.364,3.002,3.775,4.639,7.231c0.455,1.001,0.955,2.092,1.501,3.274
	c2.001,5.003,4.002,8.05,6.003,9.141c0,0.091,0.045,0.136,0.136,0.136c0.728,0.364,1.364,0.546,1.91,0.546c0.273,0,0.682,0,1.228,0
	c2.092-0.182,5.958-0.637,11.597-1.364c6.549-1.091,12.097-2.137,16.645-3.138c2.638-0.819,4.457-1.865,5.457-3.138
	c1.001-1.273,1.001-3.456,0-6.549c-1.364-4.093-4.002-11.097-7.913-21.011c-3.911-10.005-8.232-20.374-12.961-31.107
	c-4.73-10.824-9.414-20.738-14.053-29.743c-1.182-2.274-2.319-4.366-3.411-6.276c-3.365-5.548-6.231-9.277-8.595-11.188
	c-2.183-1.728-5.821-2.638-10.915-2.729c-5.094,0-10.278,0.455-15.554,1.364C232.657,90.04,227.699,91.359,223.333,93.087
	 M255.668,144.932c1.728,2.82,2.547,4.775,2.456,5.867c-0.273,2.638-3.274,4.366-9.005,5.185c-6.003,0.728-9.459-0.046-10.369-2.319
	c-0.637-1.273-0.273-4.093,1.091-8.459c1.273-4.366,2.729-7.549,4.366-9.55c1.728-2.365,4.502-0.773,8.322,4.775
	C253.713,142.067,254.759,143.568,255.668,144.932 M293.324,87.084c-0.182,4.548-0.045,9.05,0.409,13.507
	c0.364,4.457,0.91,7.686,1.637,9.687c0,0.091,0.045,0.227,0.136,0.409c0.182,0.182,0.409,0.364,0.682,0.546
	c1.001,0.819,2.956,1.592,5.867,2.319c0.728,0.091,1.501,0.227,2.319,0.409c5.366,1.001,11.87,1.865,19.51,2.592
	c0,3.365,0,6.685,0,9.96c-0.091,11.279,0.045,22.512,0.409,33.699c0.546,13.098,1.728,20.693,3.547,22.784
	c0,0.091,0.045,0.182,0.136,0.273c0.091,0,0.182,0.045,0.273,0.136c1.182,0.637,2.774,1.182,4.775,1.637
	c1.182,0.273,2.547,0.5,4.093,0.682c3.911,0.728,7.822,1.137,11.733,1.228c4.002,0.182,7.64,0.046,10.915-0.409
	c3.183-0.273,5.139-0.91,5.867-1.91c1.637-1.819,3.456-9.414,5.457-22.785c2.001-13.552,3.638-27.605,4.912-42.158
	c5.548,0.273,10.46,0.5,14.735,0.682c4.366,0.091,7.458-0.045,9.277-0.409c2.183,0.091,4.23-2.274,6.14-7.095
	c1.819-4.639,3.138-9.823,3.957-15.553c0.182-1.728,0.364-3.411,0.546-5.048c0.091-2.456,0.045-4.821-0.136-7.095
	c-0.091-1.364-0.227-2.729-0.409-4.093c-0.091-0.546-0.182-1.092-0.273-1.637c-0.455-2.001-1.137-3.502-2.047-4.502
	c-0.819-1.001-1.865-1.592-3.138-1.774c-7.367-1.001-16.827-2.001-28.378-3.002c-11.46-1.092-22.83-1.91-34.109-2.456
	c-11.279-0.637-21.238-0.91-29.879-0.819c-8.641,0-13.734,0.546-15.281,1.637c-1,0.546-1.865,2.592-2.592,6.14
	C293.87,78.398,293.506,82.536,293.324,87.084 M627.451,105.366c0.182-11.46-0.045-19.055-0.682-22.785
	c-0.364-3.092-3.047-5.457-8.05-7.095c-5.003-1.728-10.46-2.683-16.372-2.865c-5.73-0.091-11.051,0.5-15.963,1.774
	c-4.821,1.182-7.322,3.138-7.504,5.867c-0.364,4.275-0.546,9.414-0.546,15.417c-0.091,5.912,0,12.188,0.273,18.828
	c-4.457,0.091-8.732,0.227-12.825,0.409c-0.364,0-0.728,0-1.091,0c-0.091-6.731-0.227-13.098-0.409-19.101
	c-0.091-1.728-0.182-3.411-0.273-5.048c-0.273-3.82-0.591-7.322-0.955-10.505c-0.091-1.273-0.773-2.365-2.047-3.274
	c-1.273-1.001-3.183-1.865-5.73-2.592c-5.003-1.182-10.415-1.683-16.236-1.501s-11.188,1.091-16.099,2.729
	c-1.182,0.455-2.228,0.91-3.138,1.364c-2.911,1.546-4.457,3.502-4.639,5.867c-0.364,3.911-0.227,11.506,0.409,22.785
	c0.546,11.37,1.455,23.148,2.729,35.336c1.092,12.37,2.41,23.467,3.957,33.29c1,6.003,2.001,10.369,3.002,13.098
	c0.546,1.637,1.137,2.592,1.774,2.865c3.82,1.728,10.142,2.365,18.964,1.91c8.732-0.455,13.598-1.683,14.598-3.684
	c0.637-1.091,1.228-3.957,1.774-8.595c0.546-4.73,1.046-10.46,1.501-17.191c2.911-0.364,5.867-0.728,8.868-1.091
	c2.911-0.546,5.776-1.091,8.595-1.637c0.728,7.458,1.501,13.78,2.319,18.964c0.819,5.275,1.683,8.459,2.592,9.55
	c1.001,2.001,5.867,3.183,14.599,3.547c8.823,0.364,15.053-0.318,18.691-2.047c1.546-0.819,2.911-6.14,4.093-15.963
	c1.091-9.823,2.001-20.874,2.729-33.153l0.682-15.417C627.224,118.6,627.361,111.915,627.451,105.366 M625.541,28.417v0.546
	c0.011,0.523,0.057,1.023,0.136,1.501c0.273,1.91,0.91,3.775,1.91,5.594c0.728,1.364,1.728,2.683,3.001,3.957
	c2.638,3.092,5.685,5.003,9.141,5.73c1.637,0.364,3.138,0.409,4.502,0.136c1.273-0.364,2.456-1.046,3.547-2.047
	c1.364-1.182,2.183-2.729,2.456-4.639c0.091-1.001,0.046-2.137-0.136-3.411c-0.364-3.456-1.91-6.64-4.639-9.55
	c-2.911-3.092-6.003-4.912-9.277-5.457c-3.365-0.728-6.003-0.136-7.913,1.774C626.542,24.006,625.632,25.962,625.541,28.417
	 M644.233,98.954c-0.273,2.001-0.364,5.139-0.273,9.414c0,3.729,0.091,8.277,0.273,13.643c0.182,3.365,0.364,6.731,0.546,10.096
	c0.546,8.186,1.228,16.645,2.047,25.377c0.546,6.185,1.182,12.052,1.91,17.6c0.637,5.548,1.364,10.778,2.183,15.69
	c1.546,9.914,3.229,15.281,5.048,16.099c4.093,1.637,11.006,2.274,20.738,1.91c9.641-0.364,15.053-1.592,16.236-3.684h0.136
	c0.182-0.455,0.409-1.091,0.682-1.91c0.819-2.547,1.592-7.049,2.319-13.507c0.819-8.641,1.501-18.646,2.047-30.016
	c0.182-7.004,0.364-14.007,0.546-21.011c0-4.457,0-8.914,0-13.371c-0.091-11.733-0.546-21.284-1.364-28.651
	c-0.364-2.729-3.184-4.684-8.459-5.867c-2.82-0.637-5.73-1.091-8.732-1.364c-2.911-0.273-5.958-0.364-9.141-0.273
	c-6.64,0.182-12.643,1.091-18.009,2.729c-1.001,0.273-1.91,0.591-2.729,0.955C646.507,94.361,644.506,96.407,644.233,98.954
	 M648.735,54.34v0.136c-2.092,3.183-3.365,6.64-3.82,10.369c-0.182,1.001-0.273,2.001-0.273,3.002c0,0.546,0,1.137,0,1.774
	c0.091,1.091,0.227,2.092,0.409,3.002c0.819,5.275,2.911,8.141,6.276,8.595c4.184,0.728,9.732,0.364,16.645-1.091
	c6.731-1.364,13.098-2.41,19.101-3.138c2.638-0.273,4.684-1.182,6.14-2.729c0.91-1.091,1.592-2.456,2.047-4.093
	c0.273-1.364,0.409-2.911,0.409-4.639c-0.182-4.002-1.137-7.595-2.865-10.778c-1.273-2.456-3.001-4.684-5.184-6.685
	c-0.273-0.182-0.5-0.409-0.682-0.682c-3.547-2.911-7.458-4.639-11.733-5.184c-1.91-0.364-3.866-0.455-5.867-0.273
	c-3.002,0.273-5.821,0.955-8.459,2.047c-3.547,1.455-6.731,3.729-9.55,6.822C650.418,51.884,649.554,53.067,648.735,54.34
	 M678.614,24.052c0.091-0.364,0.137-0.728,0.136-1.091c0.182-1.455,0.182-3.002,0-4.639c-0.182-4.275-1.273-7.686-3.274-10.233
	c-1.91-2.638-4.139-3.729-6.685-3.274c-2.911,0.455-5.184,2.41-6.822,5.867c-1.091,2.274-1.774,4.775-2.047,7.504
	c0,0.182,0,0.364,0,0.546l-0.136,0.409c0,0.866,0,1.776,0,2.729c0.091,1.182,0.227,2.319,0.409,3.411
	c0.455,2.82,1.455,5.23,3.002,7.231c2.001,2.456,4.366,3.456,7.095,3.002c2.547-0.364,4.684-2.319,6.412-5.867
	c0-0.091,0.045-0.136,0.136-0.136C677.75,27.781,678.341,25.962,678.614,24.052 M700.853,16.957
	c-2.729,0.273-5.23,1.728-7.504,4.366c-2.274,2.638-3.638,5.594-4.093,8.868c-0.091,0.364-0.136,0.728-0.136,1.091v0.409
	l-0.136,0.273v0.273c-0.223,1.779-0.086,3.371,0.409,4.775c0.182,0.637,0.455,1.228,0.819,1.774c1.364,2.456,3.411,3.502,6.14,3.138
	c2.456-0.182,4.821-1.546,7.095-4.093c0.728-0.728,1.364-1.501,1.91-2.319c1.273-1.819,2.137-3.866,2.592-6.14
	c0.091-0.273,0.136-0.5,0.136-0.682c0.546-3.456,0.136-6.367-1.228-8.732C705.31,17.776,703.309,16.775,700.853,16.957
	 M753.107,74.396c-4.639-0.637-9.459-0.955-14.462-0.955c-4.912,0-9.459,0.318-13.643,0.955c-4.093,0.819-6.549,1.819-7.367,3.002
	c-0.273,0.455-0.5,1.228-0.682,2.319c-0.182,0.455-0.318,1.001-0.409,1.637c-0.273,1.819-0.5,4.138-0.682,6.958
	c-0.182,2.365-0.318,5.185-0.409,8.459c-0.273,5.548-0.455,11.369-0.546,17.464c15.281-6.185,31.334-9.823,48.161-10.915
	l-3.138,5.594c-13.916,3.82-28.97,9.823-45.16,18.009c0,1.001,0,2.047,0,3.138c0,1.001,0,2.001,0,3.002c0,0.728,0,1.501,0,2.319
	c0,1.273,0,2.547,0,3.82h0.136c13.007-7.913,26.832-13.825,41.476-17.736c1.273-0.364,2.547-0.682,3.82-0.955l-2.183,6.003
	c-13.552,6.458-27.878,15.281-42.977,26.468c0.182,5.73,0.455,11.233,0.819,16.509c0.273,3.456,0.546,6.503,0.819,9.141
	c0.728,5.821,1.546,9.414,2.456,10.778c0.455,0.637,2.228,1.182,5.321,1.637c3.002,0.546,6.458,0.864,10.369,0.955
	c3.82,0.182,7.64,0.227,11.461,0.136c3.456-0.091,6.185-0.409,8.186-0.955c0.091,0,0.227-0.045,0.409-0.136
	c0.364-0.091,0.728-0.409,1.092-0.955c1-1.819,2.001-5.912,3.001-12.279c0.546-4.184,1.046-8.323,1.501-12.416
	c0.455-4.366,0.819-8.641,1.091-12.825c1.001,1.001,2.82,2.865,5.457,5.594c2.456,2.638,5.185,5.412,8.186,8.322
	c2.911,2.82,5.73,5.275,8.459,7.367c2.729,2.183,4.73,3.274,6.003,3.274c1.364,0,3.456-1.137,6.276-3.411
	c2.82-2.183,5.776-4.775,8.868-7.777c2.274-2.274,4.502-4.502,6.685-6.685h0.137c0.546-0.637,1.137-1.273,1.774-1.91
	c2.638-2.82,4.457-4.775,5.457-5.867c0.546,8.823,1.455,17.6,2.729,26.332c1.273,8.55,2.683,12.961,4.229,13.234
	c3.82,0.819,10.46,1.092,19.92,0.819c4.912-0.182,8.686-0.5,11.324-0.955c2.274-0.364,3.729-0.819,4.366-1.364l0.136-0.136
	c1.455-2.001,2.456-8.55,3.002-19.647c0.637-11.006,0.955-22.966,0.955-35.882c0-12.916-0.318-24.922-0.955-36.019
	c-0.455-11.188-1.182-17.964-2.183-20.329c-0.637-1.273-3.001-2.274-7.095-3.002c-4.184-0.637-8.777-1.001-13.78-1.091
	c-5.003,0-9.823,0.318-14.462,0.955c-4.73,0.728-7.913,1.91-9.55,3.547c-3.547,3.456-7.731,8.641-12.552,15.553
	c-4.821,6.913-9.778,14.189-14.871,21.829c-5.093-7.64-10.051-14.917-14.871-21.829c-4.82-6.913-9.096-12.097-12.825-15.553
	C760.748,76.306,757.655,75.123,753.107,74.396 M894.999,105.366c-0.033,0.07-0.079,0.115-0.136,0.136
	c-0.855,1.935-1.765,4.437-2.729,7.504c-1.092,3.547-2.229,7.913-3.411,13.098c-2.365,9.641-4.502,20.056-6.412,31.243
	c-1.728,11.097-3.092,21.784-4.093,32.062c-1,10.096-1.228,17.646-0.682,22.648c0.182,1.455,0.773,2.638,1.774,3.547
	c1.273,1.364,5.867,1.819,13.78,1.364c5.457-0.273,9.55-0.637,12.279-1.091c1.091-0.182,1.955-0.364,2.592-0.546
	c0.637-0.182,1.137-0.455,1.501-0.819c0.182-0.182,0.318-0.364,0.409-0.546c0.91-1.001,2.137-3.593,3.684-7.777
	c0.91-2.456,1.819-4.684,2.729-6.685c0.546-1.637,1.091-3.138,1.637-4.502c1.001-2.638,3.502-4.184,7.504-4.639l12.552-0.955
	c1.637,0.091,3.002,0.682,4.093,1.774c1.819,1.728,3.82,5.23,6.003,10.505c2.183,5.184,4.275,8.277,6.276,9.277
	c0.637,0.364,1.273,0.546,1.91,0.546c1.728,0.091,5.958-0.364,12.688-1.364c4.821-0.728,9.141-1.455,12.961-2.183
	c1.273-0.364,2.547-0.682,3.82-0.955c1.546-0.455,2.82-1,3.82-1.637c0.455-0.364,0.864-0.728,1.228-1.091
	c0.091-0.091,0.182-0.182,0.273-0.273c1-1.273,1.046-3.502,0.136-6.685c-0.819-2.365-2.047-5.73-3.684-10.096
	c-1.182-3.183-2.592-6.822-4.229-10.915c-0.273-0.637-0.546-1.319-0.819-2.047c-0.728-1.91-1.501-3.866-2.319-5.867
	c-3.093-7.549-6.412-15.235-9.96-23.057c-4.639-10.915-9.323-20.874-14.053-29.879c-4.821-9.096-8.777-14.871-11.87-17.327
	c-2.183-1.819-5.867-2.774-11.051-2.865c-5.003,0-10.187,0.455-15.554,1.364c-5.457,1.001-10.414,2.319-14.871,3.957
	c-3.729,1.273-6.14,2.638-7.231,4.093c-0.182,0.182-0.318,0.364-0.409,0.546C895.044,105.23,894.999,105.275,894.999,105.366
	 M927.607,163.624c-5.457,0.546-8.55-0.227-9.278-2.319c-0.728-1.364-0.409-4.184,0.955-8.459v-0.136
	c1.364-4.366,2.82-7.549,4.366-9.55c1.728-2.365,4.548-0.773,8.459,4.775c3.82,5.366,5.685,8.823,5.594,10.369
	c-0.364,2.729-3.411,4.457-9.141,5.185C928.198,163.487,927.88,163.533,927.607,163.624 M1074.683,138.11
	c-1.182-0.455-3.502-0.728-6.958-0.819c-3.729-0.182-7.458-0.318-11.188-0.409c-3.82-0.182-7.231-0.455-10.233-0.819
	c-3.093-0.364-4.775-1.182-5.048-2.456c-0.091-0.637,0.091-3.866,0.546-9.687c0.364-5.912,0.773-12.37,1.228-19.374
	c0.546-7.186,1.092-14.098,1.637-20.738c0.364-6.549,0.591-10.96,0.682-13.234c-0.091-1.546-2.501-3.002-7.231-4.366
	c-4.73-1.273-9.914-2.137-15.553-2.592c-5.639-0.455-10.915-0.455-15.826,0c-3.82,0.455-6.276,1.364-7.368,2.729v0.136
	c-0.075,0.075-0.166,0.166-0.273,0.273c-0.116,0.217-0.207,0.49-0.273,0.819c-0.364,1.728-0.773,4.457-1.228,8.186
	c-0.455,3.547-0.955,8.05-1.501,13.507c-1.182,11.006-2.092,22.648-2.729,34.927c-0.819,12.097-1.228,23.285-1.228,33.563
	c0,10.187,0.773,16.236,2.319,18.146c1.728,2.092,6.276,3.82,13.643,5.184c5.275,0.91,11.006,1.683,17.191,2.319
	c-2.183-8.277-3.638-16.736-4.366-25.377c-0.182-1.273-0.318-2.547-0.409-3.82l5.73,3.138c2.365,8.55,5.548,17.509,9.55,26.878
	c4.002,0.273,7.913,0.546,11.733,0.819c-3.547-7.822-6.458-15.917-8.732-24.285c-0.364-1.182-0.682-2.41-0.955-3.684l6.003,2.183
	c4.002,8.459,8.914,17.191,14.735,26.195c2.729,0,5.093,0,7.094,0c3.456-0.091,5.958-0.318,7.504-0.682c0.182,0,0.364,0,0.546,0
	c0.273-0.182,0.546-0.364,0.818-0.546c1.182-1.001,2.138-3.229,2.865-6.685c0-0.091,0.046-0.136,0.136-0.136
	c1.001-4.548,1.637-9.55,1.91-15.008c0.091-5.639-0.228-10.915-0.955-15.826C1077.775,141.749,1076.502,138.929,1074.683,138.11
	 M1183.966,87.903h-0.136c-5.457-5.275-11.824-9.05-19.101-11.324c-7.367-2.183-15.008-2.865-22.921-2.047
	c-4.184,0.455-8.186,1.319-12.006,2.592c-3.547,1.182-6.958,2.729-10.233,4.639c-1.637,1-3.183,2.092-4.639,3.274
	c-1.91,1.455-3.593,3.002-5.048,4.639c-1.092,1.091-2.092,2.274-3.002,3.547c-3.638,4.821-6.049,10.369-7.231,16.645
	c-1.546,8.732-1.319,15.963,0.682,21.693c2.001,5.548,4.821,10.142,8.459,13.78c5.73,4.912,11.915,8.277,18.555,10.096
	c6.822,1.637,13.689,3.684,20.602,6.14c3.365,1.273,5.366,2.865,6.003,4.775c0.637,2.001,0.591,3.638-0.136,4.912
	c-0.728,1.273-2.092,2.228-4.093,2.865c-3.365,1.091-6.276,1.001-8.732-0.273h-0.136c-2.365-1.364-3.775-2.956-4.229-4.775
	c-0.182-0.455-0.318-0.864-0.409-1.228c-0.091-0.182-0.136-0.318-0.136-0.409c-0.364-1.637-2.047-3.047-5.048-4.229h-0.136
	c-3.82-1.546-8.05-2.547-12.688-3.002c-3.002-0.182-6.003-0.136-9.005,0.136c-1.819,0.091-3.502,0.318-5.048,0.682
	c-1.273,0.273-2.501,0.637-3.684,1.091c-2.911,1.001-5.002,2.319-6.276,3.957c-1.001,1.182-1.455,2.592-1.364,4.229
	c-0.091,0.364-0.091,0.728,0,1.091c1.364,10.46,6.594,19.556,15.69,27.287c10.551,9.005,23.694,13.507,39.43,13.507
	c7.549-0.091,14.462-1.319,20.738-3.684c1.819-0.728,3.593-1.501,5.321-2.319c1.728-0.91,3.365-1.91,4.912-3.002
	c2.638-1.728,5.094-3.729,7.367-6.003c8.459-8.095,12.507-17.691,12.143-28.788c-0.455-11.188-5.094-20.738-13.916-28.651
	c-5.003-4.548-11.415-8.323-19.237-11.324c-3.547-1.364-7.413-2.592-11.597-3.684h-0.136c-1.001-0.364-1.91-0.591-2.729-0.682
	h-0.136c-1.273-0.182-2.365-0.409-3.274-0.682c-4.457-1.546-7.14-3.093-8.05-4.639c-1.637-2.092-0.682-4.138,2.865-6.14v0.136
	c1.546-1.001,3.047-1.728,4.502-2.183c3.365-1.091,6.23-0.5,8.595,1.774c1.637,1.637,2.365,3.183,2.183,4.639
	c0.091,0.546,0.5,1.137,1.228,1.774c0.637,0.637,1.365,0.955,2.183,0.955h0.136c1.91,0.091,4.911-0.091,9.005-0.546h0.273
	c2.911-0.182,5.912-0.591,9.005-1.228c0.818-0.273,1.682-0.546,2.592-0.819h0.273c3.547-0.91,6.64-2.228,9.277-3.957l0.273-0.273
	c0.182-0.182,0.364-0.318,0.546-0.409c1.91-1.546,2.592-3.365,2.047-5.457l-0.137-0.136
	C1192.744,98.954,1189.333,92.632,1183.966,87.903z"
    />
  </Svg>
);

export default Logo;
