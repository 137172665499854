import React from "react";
import Svg, { Path } from "svgs";

import { animalSize } from "../constants/board";

const Hamster = (props) => (
  <Svg height={animalSize} width={animalSize} viewBox="0 0 64 64" {...props}>
    <Path
      fill="#FBB855"
      d="M21.588 20.585c-.467 4.569-14.1 13.938-16.824-2.791-2.86-17.571 18.49-13.52 16.824 2.791z"
    />
    <Path
      fill="#FDE0DA"
      d="M17.498 20.385c-.312 3.543-9.384 10.808-11.197-2.164C4.397 4.595 18.606 7.736 17.498 20.385z"
    />
    <Path
      fill="#FBB855"
      d="M42.412 20.585c.467 4.569 14.1 13.938 16.824-2.791 2.86-17.571-18.49-13.52-16.824 2.791z"
    />
    <Path
      fill="#FDE0DA"
      d="M46.502 20.385c.312 3.543 9.384 10.808 11.197-2.164 1.904-13.626-12.305-10.485-11.197 2.164z"
    />
    <Path
      fill="#FBB855"
      d="M35.695 4.546c-.523 1.664-1.576 2.963-1.576 2.963C32.714 4.201 28.603 2 28.603 2c.927 2.87.696 5.323.696 5.323-2.412-1.11-4.775-.67-4.775-.67 1.621 2.037 1.298 4.653 1.298 4.653h13.655c-.257-4.029-3.782-6.76-3.782-6.76z"
    />
    <Path
      fill="#FBB855"
      d="M54.586 29.9C50.867 14.705 44.168 8.107 32 8.107S13.133 14.705 9.414 29.9C8.076 35.364 2 36.981 2 46.416c0 22.091 25.543 14.223 30 14.223s30 7.868 30-14.223c0-9.435-6.076-11.052-7.414-16.516z"
    />
    <Path
      fill="#FFF"
      d="M43.791 38.822C35.479 41.57 37.742 15.664 32 15.664S28.521 41.57 20.209 38.822C8.888 35.079 4.855 40.124 5.659 46.6 7.985 65.334 28.762 57.052 32 57.052S56.015 65.334 58.341 46.6c.804-6.476-3.23-11.521-14.55-7.778z"
    />
    <Path
      fill="#302C3B"
      d="M46.901 32.759c0 6.667-7.567 6.667-7.567 0 0-6.666 7.567-6.666 7.567 0z"
    />
    <Path
      fill="#FFF"
      d="M43.021 31.06c0 1.666-2.325 1.666-2.325 0-.001-1.667 2.325-1.667 2.325 0z"
    />
    <Path
      fill="#302C3B"
      d="M24.665 32.759c0 6.667-7.565 6.667-7.565 0 0-6.665 7.565-6.665 7.565 0z"
    />
    <Path
      fill="#FFF"
      d="M20.785 31.06c0 1.666-2.326 1.666-2.326 0 0-1.668 2.326-1.668 2.326 0z"
    />
    <Path
      fill="#CFD8DD"
      d="M22.484 48.958c-12.948-2.063-15.725.382-15.649.898.025.168 5.685-1.573 15.649-.898zm.458 1.771c-15.877-.076-18.754 3.408-18.568 4.024.062.196 6.516-2.973 18.568-4.024zm18.575-1.771c12.948-2.063 15.726.382 15.649.898-.025.168-5.686-1.573-15.649-.898zm-.458 1.771c15.877-.075 18.754 3.408 18.567 4.024-.062.196-6.516-2.973-18.567-4.024z"
    />
    <Path
      fill="#FF8FA8"
      d="M32 51.17c-1.553 6.827-6.938 3.479-7.582 2.31 0 1.946 4.33 5.374 7.582 1.915 3.252 3.459 7.582.031 7.582-1.915-.646 1.168-6.029 4.517-7.582-2.31z"
    />
    <Path
      fill="#FF8FA8"
      d="M25.119 48.222c0-3.374 3.082-4.024 6.881-4.024 3.801 0 6.879.65 6.879 4.024 0 2.683-5.473 5.063-6.879 5.063-1.403 0-6.881-2.381-6.881-5.063z"
    />
    <Path
      fill="#F6BEC1"
      d="M26.028 48.318c0-2.749 2.593-3.279 5.79-3.279 4.262 0-5.79 7.355-5.79 3.279z"
    />
  </Svg>
);

export default Hamster;
