import React from "react";
import Svg, { Path } from "svgs";

import { animalSize } from "../constants/board";

const Frog = (props) => (
  <Svg height={animalSize} width={animalSize} viewBox="0 0 64 64" {...props}>
    <Path
      fill="#FED0AC"
      d="M61.912 26.462c-1.16-5.406-58.664-5.406-59.825 0-.86 3.995 4.895 11.597 4.895 11.597C6.982 44.094 19.481 58 32 58c12.521 0 25.016-13.906 25.016-19.941 0 0 5.757-7.602 4.896-11.597z"
    />
    <Path
      fill="#302C3B"
      d="M32 32.791c-11.315 0-29-4.352-29-4.352 2.685 4.994 20.528 17.732 29 17.732 8.476 0 26.314-12.738 29-17.732 0 0-17.686 4.352-29 4.352z"
    />
    <Path
      fill="#92C932"
      d="M58.963 21.006c-21.387-9.599-32.537-9.599-53.928 0-3.351 1.503-3.865 6.174-1.847 8.374 2.782 3.031 23.407 7.573 28.814 7.573 5.409 0 26.027-4.542 28.809-7.573 2.019-2.2 1.505-6.871-1.848-8.374z"
    />
    <Path
      fill="#297124"
      d="M24.943 28.254c1.948-1.196 5.467-1.317 4.174-2.246-2.61-1.869-6.19 3.481-4.174 2.246zm14.114 0c-1.947-1.196-5.465-1.317-4.172-2.246 2.609-1.869 6.188 3.481 4.172 2.246z"
    />
    <Path
      fill="#92C932"
      d="M50.005 29.377C34.014 29.377 34.014 6 50.005 6c15.993 0 15.993 23.377 0 23.377z"
    />
    <Path
      fill="#7AB51D"
      d="M39.461 11.84c-2.578 5.712.821 10 .256 9.587-1.172-.857-2.877-3.738-2.246-7.2 0 0 .619.234 1.99-2.387zm21.957 9.647c-2.393 5.541-7.162 6.637-6.471 6.791 1.539.341 5.578-1.906 6.672-4.375-.25-1.016-.75-.719-.201-2.416z"
    />
    <Path
      fill="#92C932"
      d="M13.996 29.377c15.991 0 15.991-23.377 0-23.377-15.994 0-15.994 23.377 0 23.377z"
    />
    <Path
      fill="#7AB51D"
      d="M24.54 11.84c2.578 5.712-.821 10-.256 9.587 1.172-.857 2.877-3.738 2.246-7.2 0 0-.619.234-1.99-2.387zM2.582 21.487c2.394 5.541 7.163 6.637 6.472 6.791-1.539.341-5.578-1.906-6.672-4.375.25-1.016.75-.719.2-2.416z"
    />
    <Path
      fill="#302C3B"
      d="M11.995 26.189c-12.667 0-12.667-19 0-19s12.667 19 0 19z"
    />
    <Path
      fill="#D47706"
      d="M11.995 25.001c-11.083 0-11.083-16.625 0-16.625s11.083 16.625 0 16.625z"
    />
    <Path
      fill="#F59E01"
      d="M10.853 21.877c-8.709 0-8.709-13.063 0-13.063 8.708 0 8.708 13.063 0 13.063z"
    />
    <Path
      fill="#302C3B"
      d="M9.969 22.625c.689-.011 2.385-2.7 2.401-5.979.015-3.28-1.653-5.904-2.343-5.896-.691.015-2.391 2.697-2.408 5.979-.015 3.282 1.656 5.908 2.35 5.896z"
    />
    <Path
      fill="#FFF"
      d="M8.125 14.461c2.371-.023 2.398-3.587.026-3.563-2.376.027-2.409 3.587-.026 3.563z"
    />
    <Path
      fill="#302C3B"
      d="M52.004 26.189c-12.664 0-12.664-19 0-19 12.668 0 12.668 19 0 19z"
    />
    <Path
      fill="#D47706"
      d="M52.004 25.002c-11.079 0-11.079-16.626 0-16.626 11.084 0 11.084 16.626 0 16.626z"
    />
    <Path
      fill="#F59E01"
      d="M50.862 21.876c-8.709 0-8.709-13.062 0-13.062 8.71 0 8.71 13.062 0 13.062z"
    />
    <Path
      fill="#302C3B"
      d="M53.979 22.626c.691-.012 2.385-2.701 2.402-5.979.016-3.279-1.652-5.903-2.342-5.896-.693.015-2.391 2.697-2.41 5.979-.012 3.281 1.66 5.908 2.35 5.896z"
    />
    <Path
      fill="#FFF"
      d="M48.138 14.462c2.369-.024 2.396-3.587.025-3.563-2.375.026-2.406 3.587-.025 3.563z"
    />
    <Path
      fill="#F46C60"
      d="M20.26 41.266s7.308 3.906 11.74 3.906c4.435 0 11.741-3.906 11.741-3.906-3.275-1.301-7.175-2.094-11.741-2.094-4.569 0-8.463.793-11.74 2.094z"
    />
    <Path
      fill="#EBBB96"
      d="M39.041 47.43S34.658 48.524 32 48.524c-2.659 0-7.042-1.094-7.042-1.094 5.992 3.968 8.09 3.966 14.083 0z"
    />
  </Svg>
);

export default Frog;
