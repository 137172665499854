import React from "react";
import Svg, { Ellipse, Path } from "svgs";

import { animalSize } from "../constants/board";

const Cat = (props) => (
  <Svg height={animalSize} width={animalSize} viewBox="0 0 64 64" {...props}>
    <Path
      fill="#F9C802"
      d="M4.948 2c-1.68 13.432-1.413 24.234 1.091 31.133L29.247 16C24.634 10.908 15.278 3.459 4.948 2z"
    />
    <Path
      fill="#AB5C10"
      d="M7.87 32.178L19.816 17S15.649 11.639 6.595 6.486C3.883 18.143 7.87 32.178 7.87 32.178z"
    />
    <Path
      fill="#82450D"
      d="M12.232 23.635L18.879 16S15.65 11.639 6.596 6.486c7.271 8.172 5.636 17.149 5.636 17.149z"
    />
    <Path
      fill="#F9C802"
      d="M59.052 2c1.681 13.432 1.411 24.234-1.091 31.133L34.753 16c4.611-5.092 13.969-12.541 24.299-14z"
    />
    <Path
      fill="#AB5C10"
      d="M56.13 32.178L44.184 17s4.167-5.361 13.221-10.514c2.712 11.657-1.275 25.692-1.275 25.692z"
    />
    <Path
      fill="#82450D"
      d="M51.767 23.635L45.121 16s3.229-4.361 12.283-9.514c-7.272 8.172-5.637 17.149-5.637 17.149z"
    />
    <Path
      fill="#F9C802"
      d="M57.797 40.126s.508-1.586 4.203-.691c0 0-1.779-4.035-4.299-4.769 0 0 1.822-.945 3.395-.515 0 0-1.534-5.26-6.222-10.885.262-.078.535-.14.832-.153 0 0-.905-1.637-2.63-2.5 0 0 1.156-1.406 3.078-2.333 0 0-3.103-1.32-5.479-.449 0 0 .167-1.3.776-2.467 0 0-1.675-.372-3.437.197-3.992-2.667-9.202-4.2-16.014-4.2-6.813 0-12.022 1.534-16.015 4.2-1.763-.569-3.438-.197-3.438-.197.611 1.167.777 2.467.777 2.467-2.377-.871-5.479.449-5.479.449 1.923.928 3.079 2.333 3.079 2.333-1.725.863-2.63 2.5-2.63 2.5.297.014.569.075.83.153-4.688 5.625-6.221 10.885-6.221 10.885 1.572-.431 3.395.515 3.395.515C3.779 35.399 2 39.435 2 39.435c3.695-.895 4.201.691 4.201.691-2.465.539-3.131 2.115-3.131 2.115S9.955 62 32 62c22.043 0 28.93-19.759 28.93-19.759s-.668-1.576-3.133-2.115z"
    />
    <Path
      fill="#D47706"
      d="M37.687 11.764c-1.762-.258-3.65-.498-5.686-.498s-3.926.24-5.689.498C27.048 15.619 32 23.348 32 23.348s4.964-7.756 5.687-11.584zm9.126 3.021c-1.908-1.092-4.072-1.943-6.523-2.523.59 2.291 3.627 6.936 3.627 6.936s2.217-2.663 2.896-4.413zM23.71 12.264c-2.451.578-4.615 1.43-6.521 2.521.686 1.766 2.891 4.412 2.891 4.412s3.048-4.664 3.63-6.933zM21.783 41.84c-11.637-1.871-14.133.346-14.064.814.023.153 5.109-1.425 14.064-.814zm.412 1.605c-14.27-.068-16.855 3.09-16.688 3.648.056.178 5.856-2.695 16.688-3.648zm20.022-1.605c11.639-1.871 14.133.346 14.064.814-.021.153-5.107-1.425-14.064-.814zm-.41 1.605c14.268-.068 16.854 3.09 16.686 3.648-.054.178-5.854-2.695-16.686-3.648z"
    />
    <Path
      fill="#302C3B"
      d="M36.042 40.611c-.811-.945-7.275-.945-8.086 0-1.24 1.451 2.439 4.982 4.043 4.982s5.287-3.53 4.043-4.982zm4.946 7.304c-5.793 3.607-12.219 3.585-17.977 0-.693-.43-1.318.316-.848 1.008 1.755 2.579 5.3 4.877 9.837 4.877 4.539 0 8.082-2.298 9.836-4.877.471-.692-.154-1.438-.848-1.008z"
    />
    <Path
      fill="#F46C60"
      d="M28.136 51.896c-1.017 2.105-1.765 4.303-1.483 5.857 1.022 5.662 9.673 5.662 10.694 0 .281-1.555-.467-3.752-1.484-5.857h-7.727z"
    />
    <Path fill="#E81C27" d="M31.999 59.877l-1.353-7.981h2.705l-1.352 7.981z" />
    <Path
      fill="#302C3B"
      d="M40.988 49.749c-5.793 3.607-12.219 3.584-17.977 0-.693-.431 4.451 1.045 8.988 1.045 4.54 0 9.683-1.476 8.989-1.045z"
    />
    <Ellipse fill="#302C3B" cx={44.356} cy={33.5} rx={4} ry={5.5} />
    <Path fill="#FFF" d="M43.626 31.135c0 1.667-2 1.667-2 0s2-1.667 2 0z" />
    <Ellipse fill="#302C3B" cx={19.643} cy={33.5} rx={4} ry={5.5} />
    <Path fill="#FFF" d="M18.912 31.135c0 1.667-2 1.667-2 0s2-1.667 2 0z" />
  </Svg>
);

export default Cat;
