import React from "react";
import Svg, { Ellipse, Path } from "svgs";

import { animalSize } from "../constants/board";

const Fox = (props) => (
  <Svg height={animalSize} width={animalSize} viewBox="0 0 64 64" {...props}>
    <Path
      fill="#EA8800"
      d="M57.891 30.273L38.412 12.465S52.799-.371 58.666 2.388c2.857 1.343 1.814 24.252-.775 27.885z"
    />
    <Path
      fill="#FFF"
      d="M56.395 29.033l-16.959-8.589s4.437-11.122 17.68-15.976c1.986 1.909 1.775 22.162-.721 24.565z"
    />
    <Path
      fill="#CFD8DD"
      d="M54.05 30.024l-14.289-7.236s3.738-9.375 14.896-13.461c1.674 1.606 1.496 18.671-.607 20.697z"
    />
    <Path
      fill="#EA8800"
      d="M6.109 30.273l19.479-17.808S11.201-.371 5.334 2.388C2.477 3.731 3.52 26.64 6.109 30.273z"
    />
    <Path
      fill="#FFF"
      d="M7.605 29.033l16.959-8.589S20.127 9.322 6.884 4.468c-1.986 1.909-1.775 22.162.721 24.565z"
    />
    <Path
      fill="#CFD8DD"
      d="M9.95 30.024l14.289-7.236S20.501 13.413 9.343 9.327c-1.674 1.606-1.496 18.671.607 20.697z"
    />
    <Path
      fill="#EA8800"
      d="M32 10.616zm20.573 35.779C57.187 45.906 62 47.723 62 47.723s-.473-15.819-7.05-25.34c-.613-3.866.066-6.938.066-6.938-1.205.205-2.131.826-2.844 1.592-.097-3.525.431-6.42.431-6.42a16.302 16.302 0 0 0-4.302 5.693C43.52 12.95 37.125 10.616 32 10.616s-11.52 2.334-16.302 5.693a16.302 16.302 0 0 0-4.302-5.693s.527 2.895.431 6.42c-.713-.766-1.639-1.387-2.844-1.592 0 0 .68 3.072.066 6.938C2.473 31.904 2 47.723 2 47.723s4.813-1.816 9.427-1.328c-2.832 1.699-4.732 7.861-4.732 7.861s8.433-5.586 11.77-2.49C21.577 54.646 19.286 61.99 32 62c12.714-.01 10.423-7.354 13.536-10.234 3.337-3.096 11.77 2.49 11.77 2.49s-1.901-6.162-4.733-7.861z"
    />
    <Path
      fill="#FFF"
      d="M60.405 45.994c-9.093-9.31-21.338.879-28.405.527-7.067.352-19.312-9.838-28.405-.527 3.531-1.406 7.406-1.533 10.238-.029-3.094 1.719-4.676 5.654-4.676 5.654s6.095-4.219 9.97-1.152c4.352 3.438.658 9.951 12.873 9.951s8.521-6.514 12.873-9.951c3.875-3.066 9.97 1.152 9.97 1.152s-1.582-3.936-4.676-5.654c2.832-1.504 6.707-1.377 10.238.029z"
    />
    <Path
      fill="#EA8800"
      d="M25.684 52.527c2.918 2.207 9.792 2.152 12.629 0 .913-.688 2.651-15.443-6.312-15.443-8.967 0-7.231 14.756-6.317 15.443z"
    />
    <Path
      fill="#302C3B"
      d="M37.965 57.436a3.732 3.732 0 0 1-2.215.586c-.765-.064-1.488-.322-2.068-.824-.579-.477-1.007-1.162-1.131-1.914h-1.102c-.125.752-.552 1.438-1.133 1.914-.566.502-1.328.76-2.048.824a3.723 3.723 0 0 1-2.232-.586 3.772 3.772 0 0 1-1.536-2.033c.039.961.525 1.887 1.218 2.527a3.996 3.996 0 0 0 2.517 1.061c.961.064 1.892-.229 2.698-.822.417-.313.78-.732 1.068-1.209.286.477.649.896 1.066 1.209.796.586 1.762.887 2.683.822a4.056 4.056 0 0 0 2.532-1.061c.693-.641 1.177-1.576 1.218-2.527-.288.898-.854 1.601-1.535 2.033z"
    />
    <Path
      fill="#302C3B"
      d="M25.268 51.311c0-3.104 3.018-3.697 6.733-3.697 3.719 0 6.729.594 6.729 3.697 0 2.471-5.354 4.66-6.729 4.66-1.372 0-6.733-2.19-6.733-4.66z"
    />
    <Path
      fill="#4E5E67"
      d="M26.158 51.4c0-2.535 2.536-3.02 5.665-3.02 4.17.001-5.665 6.766-5.665 3.02z"
    />
    <Path
      fill="#302C3B"
      d="M24.823 34.145c0 3.203-2.018 5.795-4.512 5.795-2.49 0-4.508-2.592-4.508-5.795s2.018-5.8 4.508-5.8c2.495-.001 4.512 2.597 4.512 5.8z"
    />
    <Path
      fill="#FFF"
      d="M19.989 31.611c0 1.997-2.252 1.997-2.252 0 0-2.007 2.252-2.007 2.252 0z"
    />
    <Ellipse fill="#302C3B" cx={43.687} cy={34.145} rx={4.51} ry={5.795} />
    <Path
      fill="#FFF"
      d="M43.362 31.611c0 1.997-2.252 1.997-2.252 0 0-2.007 2.252-2.007 2.252 0z"
    />
  </Svg>
);

export default Fox;
