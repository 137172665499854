import React from "react";
import Svg, { Path } from "svgs";

import { animalSize } from "../constants/board";

const Monkey = (props) => (
  <Svg height={animalSize} width={animalSize} viewBox="0 0 64 64" {...props}>
    <Path
      fill="#785647"
      d="M44.912 32.297c.467 3.85 14.1 11.742 16.824-2.351 2.86-14.803-18.49-11.39-16.824 2.351z"
    />
    <Path
      fill="#C4926E"
      d="M59.666 25.319c-3.451-2.59-10.203 1.32-9.199 9.538.053.422-1.225-.799-1.34-1.731-1.162-9.518 8.072-13.25 10.539-7.807z"
    />
    <Path
      fill="#EBBB96"
      d="M59.666 25.319c.619 1.361.818 3.288.32 5.852-1.711 8.796-9.467 4.108-9.52 3.687-1.003-8.218 5.749-12.129 9.2-9.539z"
    />
    <Path
      fill="#785647"
      d="M19.087 32.297c-.467 3.85-14.1 11.742-16.823-2.351-2.86-14.803 18.491-11.39 16.823 2.351z"
    />
    <Path
      fill="#C4926E"
      d="M4.333 25.319c3.451-2.59 10.202 1.32 9.2 9.538-.054.422 1.226-.799 1.339-1.731 1.162-9.518-8.071-13.25-10.539-7.807z"
    />
    <Path
      fill="#EBBB96"
      d="M4.333 25.319c-.618 1.361-.818 3.288-.32 5.852 1.71 8.796 9.467 4.108 9.521 3.687 1.002-8.218-5.749-12.129-9.201-9.539z"
    />
    <Path
      fill="#785647"
      d="M51.488 26.735C51.488 13.007 41.457 8.086 32 8.086c-9.458 0-19.49 4.921-19.49 18.649 0 4.174-4.483 7.685-4.512 13.267C7.93 54.391 20 60 32 60c12 0 24.072-5.604 24-20-.029-5.582-4.512-9.091-4.512-13.265z"
    />
    <Path
      fill="#785647"
      d="M38.695 9.025s-.262-1.608 2.139-3.135c0 0-5.496-.563-7.892 1.777 0 0-.41-1.899.958-3.668 0 0-6.194 0-10.433 6.339l19.526 4.001s-.326-3.155 2.297-4.565c.001 0-3.298-1.535-6.595-.749z"
    />
    <Path
      fill="#EBBB96"
      d="M32.001 22.783C16.902 14.213 7.125 32.8 19.904 41.035c-2.794 8.521-.144 15.541 12.097 15.541 12.237 0 14.888-7.02 12.095-15.541 12.779-8.235 3.002-26.822-12.095-18.252z"
    />
    <Path
      fill="#302C3B"
      d="M27.072 39.824c-.499-1.768 4.474 1.195 3.804 1.398-1.215.372-3.407.001-3.804-1.398zm9.854 0c.5-1.768-4.472 1.195-3.803 1.398 1.215.372 3.406.001 3.803-1.398zm-10.387-8.053c0 2.619-1.65 4.744-3.691 4.744-2.038 0-3.689-2.125-3.689-4.744 0-2.623 1.651-4.748 3.689-4.748 2.041 0 3.691 2.125 3.691 4.748z"
    />
    <Path
      fill="#FFF"
      d="M22.584 29.695c0 1.637-1.843 1.637-1.843 0 0-1.64 1.843-1.64 1.843 0z"
    />
    <Path
      fill="#302C3B"
      d="M44.848 31.771c0 2.619-1.652 4.744-3.691 4.744s-3.689-2.125-3.689-4.744c0-2.623 1.65-4.748 3.689-4.748s3.691 2.125 3.691 4.748z"
    />
    <Path
      fill="#FFF"
      d="M40.893 29.695c0 1.637-1.844 1.637-1.844 0 0-1.64 1.844-1.64 1.844 0z"
    />
    <Path
      fill="#302C3B"
      d="M39.936 46H24.061c-.366 0-.563.254-.563.5 0 3.635 2.98 7.5 8.5 7.5 5.521 0 8.501-3.865 8.501-7.5.001-.246-.194-.5-.563-.5z"
    />
    <Path
      fill="#F46C60"
      d="M27.5 51.57c1.301.9 2.801 1.43 4.5 1.43s3.199-.529 4.5-1.43C35.245 50.595 33.751 50 32 50c-1.752 0-3.244.595-4.5 1.57z"
    />
  </Svg>
);

export default Monkey;
