import React from "react";
import Svg, { Ellipse, Path } from "svgs";

import { animalSize } from "../constants/board";

const Koala = (props) => (
  <Svg height={animalSize} width={animalSize} viewBox="0 0 64 64" {...props}>
    <Path
      fill="#99A8AE"
      d="M28 16c0 7.179-5.822 13-12.998 13C7.821 29 2 23.179 2 16S7.821 3 15.002 3C22.178 3 28 8.821 28 16z"
    />
    <Ellipse fill="#6E8189" cx={15} cy={15.989} rx={9} ry={8.967} />
    <Path
      fill="#4E5E67"
      d="M10.988 10.558c5.284 0 9.971 2.512 12.958 6.391a8.7 8.7 0 0 0 .054-.96c0-4.954-4.029-8.969-9-8.969a8.993 8.993 0 0 0-7.419 3.897 16.47 16.47 0 0 1 3.407-.359z"
    />
    <Path
      fill="#BEC3CF"
      d="M11.362 11.331c2.148.712 3.187 2.108 3.187 2.108-3.241-.743-6.567 1.485-6.567 1.485 2.678.202 3.615 1.358 3.615 1.358-2.774.59-3.944 2.722-3.944 2.722 2.749.05 4.715 2.251 4.715 2.251l8.624-9.49c-4.978-1.43-9.63-.434-9.63-.434z"
    />
    <Path
      fill="#99A8AE"
      d="M62 16c0 7.179-5.822 13-12.998 13C41.821 29 36 23.179 36 16S41.821 3 49.002 3C56.178 3 62 8.821 62 16z"
    />
    <Ellipse fill="#6E8189" cx={49} cy={15.989} rx={9} ry={8.967} />
    <Path
      fill="#4E5E67"
      d="M53.014 10.558c-5.285 0-9.973 2.512-12.959 6.391a8.699 8.699 0 0 1-.055-.96c0-4.954 4.029-8.969 9-8.969a8.994 8.994 0 0 1 7.42 3.897 16.467 16.467 0 0 0-3.406-.359z"
    />
    <Path
      fill="#BEC3CF"
      d="M52.641 11.33c-2.15.713-3.188 2.109-3.188 2.109 3.241-.744 6.567 1.484 6.567 1.484-2.678.202-3.615 1.358-3.615 1.358 2.774.589 3.944 2.721 3.944 2.721-2.747.05-4.715 2.251-4.715 2.251l-8.624-9.49c4.976-1.428 9.631-.433 9.631-.433z"
    />
    <Path
      fill="#99A8AE"
      d="M56.939 39.514s.49-1.43 4.061-.623c0 0-1.719-3.637-4.154-4.297 0 0 1.762-.85 3.283-.463C60.129 34.131 53.551 8 32 8 10.453 8 3.875 34.131 3.875 34.131c1.52-.387 3.282.463 3.282.463C4.722 35.254 3 38.891 3 38.891c3.574-.807 4.062.623 4.062.623-2.383.484-3.027 1.904-3.027 1.904S9.227 54.65 25.101 57.494c3.343 4.674 10.459 4.674 13.8 0 15.873-2.844 21.066-16.076 21.066-16.076s-.645-1.42-3.028-1.904z"
    />
    <Ellipse fill="#302C3B" cx={20.326} cy={31.658} rx={3.951} ry={5.042} />
    <Path
      fill="#FFF"
      d="M20.044 29.452c0 1.742-1.974 1.742-1.974 0s1.974-1.742 1.974 0z"
    />
    <Path
      fill="#302C3B"
      d="M47.626 31.658c0 2.784-1.765 5.042-3.95 5.042-2.183 0-3.951-2.258-3.951-5.042 0-2.786 1.769-5.043 3.951-5.043 2.185 0 3.95 2.257 3.95 5.043z"
    />
    <Path
      fill="#FFF"
      d="M43.391 29.452c0 1.742-1.973 1.742-1.973 0s1.973-1.742 1.973 0z"
    />
    <Path
      fill="#E6EBEF"
      d="M26.46 51.709s-.476 5.4 2.687 8.094l.016-2.08s.867 1.922 2.838 3.066c1.974-1.145 2.842-3.066 2.842-3.066l.016 2.08c3.158-2.693 2.686-8.094 2.686-8.094H26.46z"
    />
    <Path
      fill="#302C3B"
      d="M24.578 44.016c0 2.363 1.848 5.805 1.834 7.754-.028 3.408 11.207 3.408 11.182 0-.019-1.949 1.832-5.391 1.832-7.754 0-11.854-14.848-11.854-14.848 0z"
    />
    <Path
      fill="#4E5E67"
      d="M29.179 37.674c-3.126.98-3.897 5.721-1.972 7.898 2.847 3.231 6.143-9.203 1.972-7.898z"
    />
  </Svg>
);

export default Koala;
