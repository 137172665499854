import React from "react";
import Svg, { Path } from "svgs";

import { animalSize } from "../constants/board";

const Bat = (props) => (
  <Svg height={animalSize} width={animalSize} viewBox="0 0 64 64" {...props}>
    <Path
      fill="#6E8189"
      d="M61.068 11.894c-7.738-1.47-14.496-.062-19.213 6.779-.318 5.271-.131 10.543-.131 10.543S36.287 32.42 32 38.22c-4.287-5.799-9.725-9.004-9.725-9.004s.187-5.272-.133-10.543c-4.716-6.84-11.474-8.249-19.212-6.779 2.32 2.956 3.021 11.394 1.02 15.831 3.506 1.718 7.651 7.241 8.248 11.53C20.016 41 27.063 44.631 32 53c4.936-8.369 11.984-12 19.801-13.745.598-4.289 4.742-9.812 8.248-11.53-2.002-4.437-1.301-12.875 1.019-15.831z"
    />
    <Path
      fill="#302C3B"
      d="M60.049 27.725c-.227-.504-16.648-9.213-16.361-9.593 3.715-4.958 9.93-6.764 18.313-5.986-6.176-2.635-16.277-.601-20.031 4.861-2.412-1.75-3.932.106-3.693.042 1.795-.481 2.734 1.025 2.617 2.805-.344 5.27-.014 8.363-.014 8.363s-4.593 2.155-8.88 7.954c-4.287-5.799-8.879-7.954-8.879-7.954s.329-3.094-.016-8.363c-.115-1.78.822-3.287 2.617-2.805.24.064-1.281-1.792-3.692-.042C18.275 11.544 8.176 9.51 2 12.145c8.383-.777 14.597 1.028 18.313 5.986.285.38-16.135 9.089-16.362 9.593.396.195 18.067-8.47 17.268-7.51-7.639 9.165-9.06 18.653-9.02 19.041.038.365 3.768-9.381 9.257-16.797.369-.5.145 4.957.051 7.412C28.09 33.542 32 39.556 32 39.556s3.91-6.014 10.494-9.686c-.094-2.456-.318-7.912.051-7.412 5.488 7.416 9.217 17.163 9.256 16.797.039-.388-1.381-9.875-9.02-19.041-.801-.96 16.871 7.705 17.268 7.511z"
    />
    <Path
      fill="#302C3B"
      d="M26.119 31.355C22.252 26.637 24.99 24.153 24.334 21c2.684.049 3.284 3.823 6.973 4.449-.504.386-4.786 5.344-5.188 5.906z"
    />
    <Path
      fill="#4E5E67"
      d="M26.917 31.355c-3.551-3.874-1.681-6.581-1.962-8.443 2.018.746 1.185 4.043 4.873 4.668-.503.386-2.508 3.213-2.911 3.775z"
    />
    <Path
      fill="#302C3B"
      d="M37.879 31.355c3.867-4.718 1.129-7.202 1.787-10.354-2.684.049-3.285 3.823-6.973 4.449.502.385 4.784 5.343 5.186 5.905z"
    />
    <Path
      fill="#4E5E67"
      d="M37.082 31.355c3.551-3.874 1.682-6.581 1.961-8.443-2.016.746-1.184 4.043-4.871 4.668.502.386 2.508 3.213 2.91 3.775z"
    />
    <Path
      fill="#302C3B"
      d="M27.113 33.546c-7.4-11.268 17.174-11.257 9.773 0 2.32 3.125 2.537 5.902 2.537 5.902s-.209-.349-.709-.688c.141.615.299 1.697.174 3.184 0 0-.203-.307-.545-.693.049.613.049 1.456-.109 2.555l-.559-.951a33.63 33.63 0 0 1-1.219 3.405V43.8s-1.91 10.082-2.193 6.075c-1.9 4.166-2.627 4.166-4.525 0-.284 4.007-2.193-6.075-2.193-6.075v2.46a33.706 33.706 0 0 1-1.221-3.405l-.56.951c-.155-1.099-.155-1.941-.112-2.555-.34.387-.539.693-.539.693-.129-1.486.031-2.568.17-3.184-.497.339-.709.688-.709.688s.219-2.777 2.539-5.902z"
    />
    <Path
      fill="#6E8189"
      d="M28.688 38.587c0-.577.293-.803.293-.803.299.954 1.443 1.673 1.443 1.673-.259-.513 0-1.026 0-1.026.461.62 1.578 1.352 1.578 1.352s1.117-.731 1.576-1.352c0 0 .26.514 0 1.026 0 0 1.146-.719 1.439-1.673 0 0 .295.226.295.803l1.145-1.33c-.357 1.481-1.145 2.378-1.996 2.777 0 0 .268-.646.33-1.016 0 0-1.006 1.082-2.139 1.512 0 0 .295-.537.383-1.073l-1.033 1.073-1.035-1.073c.088.536.382 1.073.382 1.073-1.131-.43-2.14-1.512-2.14-1.512.068.37.33 1.016.33 1.016-.852-.399-1.635-1.296-1.997-2.777l1.146 1.33z"
    />
    <Path
      fill="#1A1626"
      d="M28.28 29.663c-1.409 0-1.409-2.451 0-2.451 1.408 0 1.408 2.451 0 2.451z"
    />
    <Path
      fill="#FFF"
      d="M28.091 28.008a.297.297 0 0 1-.591 0c0-.427.591-.427.591 0z"
    />
    <Path
      fill="#1A1626"
      d="M35.721 29.663c-1.41 0-1.41-2.451 0-2.451 1.406 0 1.406 2.451 0 2.451z"
    />
    <Path
      fill="#FFF"
      d="M35.531 28.008a.297.297 0 0 1-.592 0c0-.427.592-.427.592 0z"
    />
    <Path
      fill="#4E5E67"
      d="M30 30.186c0-.98.895-1.17 2-1.17s2 .189 2 1.17c0 .779-1.592 1.473-2 1.473s-2-.694-2-1.473z"
    />
    <Path
      fill="#6E8189"
      d="M30.264 30.213c0-.799.754-.953 1.684-.953 1.238 0-1.684 2.139-1.684.953z"
    />
  </Svg>
);

export default Bat;
