import React from "react";
import Svg, { Ellipse, Path } from "svgs";

import { animalSize } from "../constants/board";

const Tiger = (props) => (
  <Svg height={animalSize} width={animalSize} viewBox="0 0 64 64" {...props}>
    <Path
      fill="#F59E01"
      d="M41.799 10.868c0 6.143 3.695 11.232 8.248 11.118 13.373-.338 11.881-16.048 7.861-19.148-3.662-2.824-16.109 1.894-16.109 8.03z"
    />
    <Path
      fill="#FFE8BB"
      d="M45.965 12.149c0 4.033 2.428 7.376 5.416 7.301 8.781-.222 7.803-10.537 5.162-12.572-2.404-1.856-10.578 1.242-10.578 5.271z"
    />
    <Path
      fill="#F5CFA0"
      d="M45.965 12.149c0 12.801 2.391-1.746 10.578-5.271-2.404-1.856-10.578.477-10.578 5.271z"
    />
    <Path
      fill="#F59E01"
      d="M22.203 10.868c0 6.143-3.697 11.232-8.248 11.118C.582 21.648 2.074 5.938 6.092 2.838c3.662-2.824 16.111 1.894 16.111 8.03z"
    />
    <Path
      fill="#FFE8BB"
      d="M18.035 12.149c0 4.033-2.426 7.376-5.416 7.301-8.781-.222-7.801-10.537-5.162-12.573 2.404-1.855 10.578 1.243 10.578 5.272z"
    />
    <Path
      fill="#F5CFA0"
      d="M18.035 12.149c0 12.801-2.389-1.746-10.578-5.271 2.404-1.856 10.578.477 10.578 5.271z"
    />
    <Path
      fill="#F59E01"
      d="M31.996 5.536c-11.133 0-26.979-1.041-26.979 36.986 0 5.207 26.979 6.662 26.979 6.662s26.986-1.455 26.986-6.662c0-38.027-15.853-36.986-26.986-36.986z"
    />
    <Path
      fill="#FFE8BB"
      d="M62 37.379c-.221-9.255-6.313-18.539-6.313-18.539s2.428 8.857 2.32 11.912c0 0-1.15-1.035-2.756-1.453 1.75 5.99-.297 19.742-23.256 19.742s-25-13.752-23.248-19.742c-1.607.418-2.758 1.453-2.758 1.453-.106-3.054 2.324-11.912 2.324-11.912S2.221 28.124 2 37.379l2.748-1.633S1.9 41.85 4.965 49.332l2.881-3.385s.766 7.084 8.51 10.707l.352-3.094s5.842 4.6 15.289 4.6 15.297-4.6 15.297-4.6l.352 3.094c7.744-3.623 8.51-10.707 8.51-10.707l2.881 3.385c3.064-7.482.217-13.586.217-13.586L62 37.379z"
    />
    <Path
      fill="#302C3B"
      d="M31.998 7.692c-1.625 2.076-13.629 2.545-15.281 7.76 3.311-2.291 11.824-3.877 15.281-1.105 3.457-2.771 11.951-1.186 15.26 1.105-1.651-5.215-13.633-5.684-15.26-7.76zm0 7.873c-.996 1.541-8.359.154-9.371 4.025 0 0 5.199-3.133 9.371.916 4.174-4.049 9.352-.916 9.352-.916-1.012-3.871-8.356-2.484-9.352-4.025zm-4.922 31.288s-7.396-.611-12.045-7.457c-1.02.97-1.227 2.39-1.227 2.39-1.771-1.962-2.348-5.594-2.348-5.594-1.027.543-1.736 1.397-1.736 1.397-1.209-4.069-.832-8.077-.832-8.077s-3.834 7.91-2.459 14.694c0 0 .625-2.143 1.934-3.154 1.691 7.163 6.066 10.419 6.066 10.419.156-2.426.541-3.09.541-3.09 2.268 3.172 6.168 4.754 6.168 4.754s5.659-2.778 5.938-6.282z"
    />
    <Path
      fill="#F59E01"
      d="M24.73 43.39L9.303 26.533s-3.254 7.689-2.086 14.284c0 0 .529-2.082 1.639-3.066 1.436 6.964 5.146 10.129 5.146 10.129.135-2.357.461-3.004.461-3.004 1.924 3.084 5.23 4.621 5.23 4.621s4.801-2.699 5.037-6.107z"
    />
    <Path
      fill="#302C3B"
      d="M10.508 34.768c1.881 9.211 10.68 8.262 10.68 8.262-4.371-2.512-6.725-5.719-6.725-5.719-.24.943-.172 1.602-.172 1.602-1.902-1.311-3.783-4.145-3.783-4.145zm26.416 12.085s7.396-.611 12.045-7.457c1.018.97 1.225 2.39 1.225 2.39 1.771-1.962 2.35-5.594 2.35-5.594a6.418 6.418 0 0 1 1.734 1.397c1.209-4.069.834-8.077.834-8.077s3.834 7.91 2.459 14.694c0 0-.625-2.143-1.936-3.154-1.689 7.163-6.066 10.419-6.066 10.419-.156-2.426-.541-3.09-.541-3.09-2.268 3.172-6.166 4.754-6.166 4.754s-5.661-2.778-5.938-6.282z"
    />
    <Path
      fill="#F59E01"
      d="M39.268 43.39l15.428-16.857s3.254 7.689 2.088 14.284c0 0-.531-2.082-1.641-3.066-1.436 6.964-5.146 10.129-5.146 10.129-.133-2.357-.459-3.004-.459-3.004-1.924 3.084-5.23 4.621-5.23 4.621s-4.804-2.699-5.04-6.107z"
    />
    <Path
      fill="#302C3B"
      d="M53.492 34.768C51.609 43.979 42.81 43.03 42.81 43.03c4.371-2.512 6.727-5.719 6.727-5.719.24.943.172 1.602.172 1.602 1.9-1.311 3.783-4.145 3.783-4.145z"
    />
    <Ellipse fill="#302C3B" cx={43.357} cy={31.192} rx={4} ry={5.5} />
    <Path fill="#FFF" d="M42.627 28.827c0 1.667-2 1.667-2 0s2-1.667 2 0z" />
    <Ellipse fill="#302C3B" cx={20.643} cy={31.192} rx={4} ry={5.5} />
    <Path fill="#FFF" d="M19.912 28.827c0 1.667-2 1.667-2 0s2-1.667 2 0z" />
    <Path
      fill="#FFE8BB"
      d="M38.814 38.211c-.379-.932.266 2.653.287 5.346-.209-.336-.368-.582-.436-.713H25.332c-.07.137-.24.397-.463.756.023-2.689.665-6.256.287-5.326C21.369 47.6 22.2 52.558 22.2 52.558c.255 2.676 1.685 5.493 5.491 7.886l.906-1.73s1.068 2.443 3.398 3.287c2.33-.844 3.404-3.287 3.404-3.287l.908 1.73c3.821-2.401 5.247-5.232 5.493-7.919.031.031.802-4.987-2.986-14.314z"
    />
    <Path
      fill="#DB7F9A"
      d="M24.625 44.232c0-2.259 3.295-2.693 7.361-2.693s7.359.435 7.359 2.693c0 1.797-5.857 3.391-7.359 3.391s-7.361-1.594-7.361-3.391z"
    />
    <Path
      fill="#FF8FA8"
      d="M25.596 44.297c0-1.841 2.773-2.193 6.195-2.193 4.559 0-6.195 4.921-6.195 2.193z"
    />
    <Path
      fill="#302C3B"
      d="M32.012 53.762c-3.758 0-7.18-1.295-7.18-2.717 0-.158.053-.295.156-.402a.776.776 0 0 1 .563-.219c.266 0 .521.104.947.275.936.377 2.68 1.076 5.516 1.076 2.832 0 4.574-.699 5.512-1.076.428-.172.686-.275.949-.275.355 0 .717.213.717.625-.004 1.418-3.424 2.713-7.18 2.713z"
    />
  </Svg>
);

export default Tiger;
