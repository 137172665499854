import React from "react";
import Svg, { Path } from "svgs";

import { animalSize } from "../constants/board";

const Bunny = (props) => (
  <Svg height={animalSize} width={animalSize} viewBox="0 0 64 64" {...props}>
    <Path
      fill="#99A8AE"
      d="M21.89 37.594C-.236 25.967 7.019-.491 10.484 2.19 33.907 20.291 25.923 39.709 21.89 37.594z"
    />
    <Path
      fill="#FDE0DA"
      d="M19.345 34.426C2.835 25.318 8.248 4.592 10.835 6.693c17.477 14.179 11.518 29.391 8.51 27.733z"
    />
    <Path
      fill="#F6BEC1"
      d="M19.351 34.432c-.59-.325-1.094-.699-1.635-1.055.627-6.894-.58-15.733-5.523-25.485 15.728 13.832 10.078 28.149 7.158 26.54z"
    />
    <Path
      fill="#99A8AE"
      d="M42.108 37.594C64.235 25.967 56.979-.491 53.515 2.19c-23.424 18.101-15.44 37.519-11.407 35.404z"
    />
    <Path
      fill="#FDE0DA"
      d="M44.653 34.426c16.512-9.108 11.098-29.833 8.51-27.733-17.476 14.179-11.517 29.391-8.51 27.733z"
    />
    <Path
      fill="#F6BEC1"
      d="M44.647 34.432c.59-.325 1.092-.699 1.635-1.055-.627-6.894.58-15.733 5.523-25.485-15.728 13.832-10.077 28.149-7.158 26.54z"
    />
    <Path
      fill="#99A8AE"
      d="M16.263 36.425c0-6.763 4.873-11.628 15.738-11.628 10.865 0 15.734 4.865 15.734 11.628 0 5.662 8.178 13.976.891 19.52C45.104 58.618 39.032 61 32.001 61c-7.033 0-13.109-2.382-16.627-5.056-7.289-5.544.889-13.858.889-19.519z"
    />
    <Path
      fill="#FFF"
      d="M37.392 46.821c-4.16-3.493-1.77-12.97-3.971-16.024-.992-1.38-1.849-1.38-2.843 0-2.201 3.054.189 12.53-3.971 16.024-3.125 2.627-10.691-1.333-11.611.952-1.818 4.513 6.727 12.132 17.006 12.132 10.273 0 18.82-7.619 17.001-12.132-.92-2.284-8.486 1.673-11.611-.952z"
    />
    <Path
      fill="#99A8AE"
      d="M27.275 23.108c1.037 1.491.416 3.524.416 3.524h9.035c-.336-3.788-3.191-4.968-3.191-4.968.389 1.466-.088 2.644-.088 2.644-.791-2.063-3.48-2.846-3.48-2.846.911 1.521.55 2.538.55 2.538-1.449-1.219-3.242-.892-3.242-.892z"
    />
    <Path
      fill="#302C3B"
      d="M44.261 42.665c0 5.334-6.053 5.334-6.053 0 0-5.333 6.053-5.333 6.053 0z"
    />
    <Path
      fill="#FFF"
      d="M41.157 41.305c0 1.333-1.859 1.333-1.859 0s1.859-1.333 1.859 0z"
    />
    <Path
      fill="#302C3B"
      d="M25.791 42.665c0 5.334-6.053 5.334-6.053 0 0-5.333 6.053-5.333 6.053 0z"
    />
    <Path
      fill="#FFF"
      d="M22.687 41.305c0 1.333-1.861 1.333-1.861 0s1.861-1.333 1.861 0z"
    />
    <Path
      fill="#6E8189"
      d="M21.669 49.775c-14.058-2.495-17.072.461-16.99 1.086.029.202 6.172-1.901 16.99-1.086zm.498 2.141C4.929 51.825 1.806 56.035 2.009 56.78c.066.237 7.074-3.593 20.158-4.864zm20.164-2.141c14.057-2.495 17.072.461 16.988 1.086-.027.202-6.172-1.901-16.988-1.086zm-.498 2.141c17.236-.091 20.359 4.119 20.158 4.864-.068.237-7.074-3.593-20.158-4.864z"
    />
    <Path fill="#FFF" d="M30.369 59.345h3.261V62h-3.261z" />
    <Path
      fill="#F6BEC1"
      d="M32 56.452c-1.063 4.673-4.748 2.381-5.189 1.581 0 1.333 2.963 3.678 5.189 1.312 2.226 2.366 5.189.021 5.189-1.312-.442.8-4.127 3.092-5.189-1.581z"
    />
    <Path
      fill="#F6BEC1"
      d="M27.291 54.435c0-2.309 2.109-2.754 4.709-2.754 2.601 0 4.708.445 4.708 2.754 0 1.836-3.746 3.467-4.708 3.467-.961-.001-4.709-1.631-4.709-3.467z"
    />
    <Path
      fill="#FDE0DA"
      d="M27.912 54.501c0-1.882 1.775-2.245 3.963-2.245 2.917 0-3.963 5.034-3.963 2.245z"
    />
  </Svg>
);

export default Bunny;
