import React from "react";
import Svg, { Path } from "svgs";

import { animalSize } from "../constants/board";

const Wolf = (props) => (
  <Svg height={animalSize} width={animalSize} viewBox="0 0 64 64" {...props}>
    <Path
      fill="#6E8189"
      d="M57.35 31.577L38.277 12.949S52.365-.479 58.109 2.407c2.797 1.405 1.777 25.37-.759 29.17z"
    />
    <Path
      fill="#FFF"
      d="M55.885 30.28L39.28 21.296S43.623 9.66 56.59 4.583c1.945 1.997 1.739 23.184-.705 25.697z"
    />
    <Path
      fill="#CFD8DD"
      d="M53.589 31.317l-13.991-7.57s3.661-9.806 14.586-14.082c1.639 1.681 1.464 19.532-.595 21.652z"
    />
    <Path
      fill="#6E8189"
      d="M6.649 31.577L25.72 12.95S11.634-.478 5.89 2.408c-2.798 1.404-1.777 25.369.759 29.169z"
    />
    <Path
      fill="#FFF"
      d="M8.114 30.28l16.604-8.984S20.374 9.66 7.408 4.583C5.464 6.58 5.67 27.767 8.114 30.28z"
    />
    <Path
      fill="#CFD8DD"
      d="M10.41 31.317l13.99-7.57S20.74 13.941 9.815 9.665c-1.639 1.681-1.464 19.532.595 21.652z"
    />
    <Path
      fill="#6E8189"
      d="M61.215 48.066s-.6-5.883-3.102-9.482c-.576-.828 3.887.943 3.887.943S58.895 27.58 51.73 20.852c-.305-2.219.006-4.048.006-4.048-.414.178-.982.509-1.594.948-.229-1.787-.125-3.148-.125-3.148-.637.391-1.637 1.252-2.553 2.35C42.758 13.274 37.203 11.67 32 11.67v.001c-5.203 0-10.758 1.603-15.465 5.284-.916-1.098-1.917-1.958-2.553-2.35 0 0 .103 1.36-.125 3.148-.61-.439-1.181-.771-1.594-.948 0 0 .311 1.829.006 4.048C5.105 27.582 2 39.527 2 39.527s4.464-1.771 3.889-.941c-2.503 3.6-3.103 9.48-3.103 9.48s10.108-1.459 8.534 0c-1.273 1.18-4.043 1.18-4.043 1.18 3.742 2.434 11.976 1.668 11.976 1.668 5.94 4.262 19.556 4.26 25.495 0 0 0 8.232.766 11.975-1.67 0 0-2.77 0-4.041-1.178-1.577-1.459 8.533 0 8.533 0z"
    />
    <Path
      fill="#E6EBEF"
      d="M57.521 44.537s-1.707-4.459-5.375-7.064c-.465-.332 2.293-1.76 5.125-1.076C51.417 21.135 39.07 18.694 39.07 47.609c0 5.084-14.141 5.084-14.141 0 0-28.92-12.35-26.467-18.201-11.213 2.832-.684 5.59.744 5.125 1.076-3.668 2.605-5.375 7.064-5.375 7.064 5.791-1.859 9.758 1.744 9.5 2.148-.959 1.496-3.084 2.596-3.084 2.596 1.946 1.293 4.564 1.564 5.739 1.62.926 4.532 3.469 8.134 6.573 9.446l1.431-1.836s1.688 2.592 5.369 3.488c3.681-.896 5.377-3.488 5.377-3.488l1.435 1.836c3.107-1.313 5.631-4.917 6.548-9.445 1.177-.055 3.792-.327 5.739-1.621 0 0-2.125-1.1-3.084-2.594-.257-.403 3.709-4.008 9.5-2.149z"
    />
    <Path
      fill="#302C3B"
      d="M22.656 36.482c0 2.76-1.789 4.998-4.001 4.998-2.21 0-3.999-2.238-3.999-4.998 0-2.762 1.789-5.001 3.999-5.001 2.212 0 4.001 2.24 4.001 5.001z"
    />
    <Path
      fill="#FFF"
      d="M18.369 34.295c0 1.727-1.997 1.727-1.997 0 0-1.726 1.997-1.726 1.997 0z"
    />
    <Path
      fill="#302C3B"
      d="M49.344 36.482c0 2.758-1.785 4.998-4.002 4.998-2.209 0-3.998-2.24-3.998-4.998 0-2.762 1.789-5.001 3.998-5.001 2.217 0 4.002 2.24 4.002 5.001z"
    />
    <Path
      fill="#FFF"
      d="M45.055 34.295c0 1.727-1.998 1.727-1.998 0 0-1.726 1.998-1.726 1.998 0z"
    />
    <Path
      fill="#302C3B"
      d="M38.838 48.049c-1.373-1.072-12.309-1.072-13.677 0-.975.764 2.614 5.648 6.837 5.648 4.229 0 7.816-4.884 6.84-5.648z"
    />
    <Path
      fill="#302C3B"
      d="M39.088 55.457c-.805.49-1.734.688-2.633.646-.908-.068-1.768-.355-2.457-.902-.688-.527-1.197-1.281-1.344-2.111h-1.311c-.148.83-.654 1.584-1.346 2.111-.676.547-1.578.832-2.436.9-.945.041-1.834-.156-2.652-.646a4.18 4.18 0 0 1-1.824-2.23c.047 1.053.621 2.076 1.447 2.783.816.707 1.934 1.121 2.988 1.168 1.145.063 2.248-.26 3.209-.91a4.797 4.797 0 0 0 1.268-1.328c.342.525.773.982 1.27 1.328.945.648 2.094.973 3.188.908a5.01 5.01 0 0 0 3.01-1.168c.824-.707 1.398-1.73 1.447-2.781a4.18 4.18 0 0 1-1.824 2.232z"
    />
  </Svg>
);

export default Wolf;
