import React from "react";
import Svg, { Circle, Path } from "svgs";

import { animalSize } from "../constants/board";

const Owl = (props) => (
  <Svg height={animalSize} width={animalSize} viewBox="0 0 64 64" {...props}>
    <Path
      fill="#45332C"
      d="M32.002 57.573c-28.265 0-28.265-50 0-50 28.26 0 28.26 50 0 50z"
    />
    <Path
      fill="#45332C"
      d="M12.396 21.09s-7.837 4.234-4.62 17.594c0 0 .225-1.286 1.083-2.101 0 0-.909 5.547 3.461 11.849 0 0 .175-1.346.455-2.691.667 6.243 7.202 7.681 7.202 7.681L12.396 21.09zm39.208 0s7.837 4.234 4.62 17.594c0 0-.225-1.286-1.083-2.101 0 0 .909 5.547-3.461 11.849 0 0-.175-1.346-.455-2.691-.667 6.243-7.202 7.681-7.202 7.681l7.581-32.332z"
    />
    <Path
      fill="#785647"
      d="M32.001 54.573c-22.611 0-22.611-40 0-40 22.608 0 22.608 40 0 40z"
    />
    <Path
      fill="#785647"
      d="M16.316 25.387s-6.27 3.388-3.696 14.074c0 0 .18-1.028.867-1.681 0 0-.728 4.438 2.769 9.48 0 0 .14-1.077.364-2.153.533 4.993 5.761 6.144 5.761 6.144l-6.065-25.864zm31.368 0s6.27 3.388 3.696 14.074c0 0-.18-1.028-.867-1.681 0 0 .728 4.438-2.769 9.48 0 0-.14-1.077-.364-2.153-.533 4.993-5.761 6.144-5.761 6.144l6.065-25.864z"
    />
    <Path
      fill="#FFE8BB"
      d="M23.987 35.279c-1.25 3.359-3.098 4.234-3.098 4.234C28.256 41.412 32 31.484 32 31.484s3.744 9.928 11.112 8.029c0 0-1.849-.875-3.101-4.234 3.264 2.125 6.028.324 6.028.324L35.38 19.933h-6.762L17.96 35.604s2.763 1.8 6.027-.325z"
    />
    <Path
      fill="#FFE8BB"
      d="M32 44.723s4.545-15.572 0-15.572c-4.54-.001 0 15.572 0 15.572z"
    />
    <Path
      fill="#FFE8BB"
      d="M37.437 41.605s-2.209-12.748-5.324-10.668c-3.111 2.079 5.324 10.668 5.324 10.668z"
    />
    <Path
      fill="#FFE8BB"
      d="M31.889 30.938c-3.114-2.08-5.323 10.668-5.323 10.668s8.434-8.59 5.323-10.668z"
    />
    <Path
      fill="#F59E01"
      d="M25.237 54.822c-.467-1.732-2.742-1.732-3.209 0-.926-.865-2.925-.498-2.925 1.113 0 2.096 1.695 5.217 1.695 5.217s.939-1.73 1.42-3.48C22.7 59.85 23.633 62 23.633 62s.935-2.15 1.416-4.328c.479 1.75 1.418 3.48 1.418 3.48s1.696-3.121 1.696-5.217c0-1.611-1.998-1.978-2.926-1.113z"
    />
    <Path
      fill="#785647"
      d="M20.052 55.045c-.559.93-1.385 1.174-1.385 1.174 3.293.525 4.966-2.225 4.966-2.225s1.673 2.75 4.965 2.225c0 0-.826-.244-1.385-1.174 1.458.588 2.693.09 2.693.09s-3.562-2.809-3.562-4.34c0-1.113-5.425-.961-5.425 0 0 1.531-3.561 4.34-3.561 4.34s1.236.498 2.694-.09z"
    />
    <Path
      fill="#F59E01"
      d="M44.897 55.936c0-1.611-1.999-1.979-2.926-1.113-.467-1.732-2.742-1.732-3.209 0-.925-.865-2.926-.498-2.926 1.113 0 2.096 1.696 5.217 1.696 5.217s.94-1.73 1.421-3.48C39.436 59.85 40.368 62 40.368 62s.934-2.15 1.415-4.328c.48 1.75 1.419 3.48 1.419 3.48s1.695-3.121 1.695-5.216z"
    />
    <Path
      fill="#785647"
      d="M36.786 55.045c-.558.93-1.384 1.174-1.384 1.174 3.291.525 4.966-2.225 4.966-2.225s1.672 2.75 4.964 2.225c0 0-.825-.244-1.385-1.174 1.459.588 2.693.09 2.693.09s-3.563-2.809-3.563-4.34c0-1.113-5.423-.961-5.423 0 0 1.531-3.562 4.34-3.562 4.34s1.236.498 2.694-.09z"
    />
    <Path
      fill="#45332C"
      d="M61.215 29.949s-.598-4.519-3.102-7.279c-.576-.639 3.887.723 3.887.723S58.326 2 31.999 2C5.676 2 2 23.393 2 23.393s4.463-1.359 3.887-.722c-2.503 2.761-3.1 7.278-3.1 7.278s3.996.133 6.488.906C22.257 34.884 32.001 29.7 32.001 29.7s9.733 5.182 22.722 1.153c2.493-.773 6.492-.904 6.492-.904z"
    />
    <Path
      fill="#785647"
      d="M31.875 17.775c0 6.627-5.376 12.001-12.001 12.001-6.626 0-11.999-5.374-11.999-12.001 0-6.624 5.373-11.999 11.999-11.999 6.625 0 12.001 5.375 12.001 11.999zm24.251 0c0 6.627-5.376 12.002-11.999 12.002-6.625 0-12.001-5.375-12.001-12.002 0-6.626 5.376-12 12.001-12 6.623 0 11.999 5.374 11.999 12z"
    />
    <Circle fill="#F9C802" cx={19.875} cy={16.792} r={8.5} />
    <Circle fill="#302C3B" cx={19.875} cy={16.791} r={4.521} />
    <Path
      fill="#FFF"
      d="M18.13 13.913c0 2.829-3.392 2.829-3.392 0s3.392-2.829 3.392 0z"
    />
    <Circle fill="#F9C802" cx={44.127} cy={16.792} r={8.5} />
    <Circle fill="#302C3B" cx={44.127} cy={16.791} r={4.521} />
    <Path
      fill="#FFF"
      d="M42.382 13.913c0 2.829-3.392 2.829-3.392 0s3.392-2.829 3.392 0z"
    />
    <Path
      fill="#785647"
      d="M54.969 2c-7.354 4.432-21.084.996-22.967 12.799C40.571 7.775 53.812 14.476 54.969 2zM9.034 2c7.354 4.432 21.084.996 22.968 12.799C23.432 7.775 10.19 14.476 9.034 2z"
    />
    <Path
      fill="#785647"
      d="M32.002 3s-6.002 10.429 0 18c6.002-7.572 0-18 0-18z"
    />
    <Path
      fill="#785647"
      d="M26.454 5.09s2.254 8.873 5.434 7.424c3.175-1.444-5.434-7.424-5.434-7.424z"
    />
    <Path
      fill="#785647"
      d="M32.116 12.514c3.179 1.449 5.433-7.424 5.433-7.424s-8.609 5.98-5.433 7.424z"
    />
    <Path
      fill="#F59E01"
      d="M28.519 21.866C28.826 24.396 30.868 35 32 35c1.136 0 3.175-10.604 3.48-13.134.463-3.821-7.422-3.821-6.961 0z"
    />
  </Svg>
);

export default Owl;
