export const colors = {
  redLight: "#FF9E9C",
  redMedium: "#DB7270",
  orangeMedium: "#CD7547",
  yellowLight: "#FFEA87",
  greenLight: "#CAE1C3",
  greenMedium: "#7A9572",
  blueLight: "#C5E5F0",
  blueMedium: "#7698A4",
  blueDark: "#364F57",
  purpleMedium: "#94638D",
  grayLight: "#9F9FB7",
  grayMedium: "#5D5D6B",
  grayDark: "#17171b",
};
