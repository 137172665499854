import React from "react";
import Svg, { Path } from "svgs";

import { animalSize } from "../constants/board";

const Cow = (props) => (
  <Svg height={animalSize} width={animalSize} viewBox="0 0 64 64" {...props}>
    <Path
      fill="#6E8189"
      d="M50.649 27.9C67.295 18.942 61.293.498 59.296 2.098c-3.127 2.496-12.086 8.431-17.305 17.289-.557.954 6.577 9.634 8.658 8.513z"
    />
    <Path
      fill="#4E5E67"
      d="M52.494 24.228c11.307-6.355 7.6-20.815 5.826-19.351-11.966 9.895-7.886 20.507-5.826 19.351z"
    />
    <Path
      fill="#302C3B"
      d="M52.49 24.23c.401-.227.748-.487 1.119-.735-.43-4.81.396-10.977 3.783-17.781-10.772 9.651-6.902 19.64-4.902 18.516z"
    />
    <Path
      fill="#6E8189"
      d="M13.351 27.9C-3.295 18.942 2.708.499 4.703 2.098c3.126 2.496 12.086 8.431 17.305 17.289.559.954-6.576 9.634-8.657 8.513z"
    />
    <Path
      fill="#4E5E67"
      d="M11.507 24.227C.2 17.873 3.907 3.413 5.679 4.878c11.968 9.893 7.888 20.506 5.828 19.349z"
    />
    <Path
      fill="#302C3B"
      d="M11.511 24.23c-.404-.227-.748-.487-1.12-.735.43-4.81-.396-10.977-3.783-17.78 10.772 9.65 6.903 19.639 4.903 18.515z"
    />
    <Path
      fill="#F6C799"
      d="M26.133 11.179C17.588 9.233 16.59.923 15.951 2.117c-1.165 2.177-2.08 9.887 4.615 13.883l5.567-4.821zm11.733 0C46.409 9.233 47.41.924 48.049 2.117c1.164 2.177 2.08 9.887-4.617 13.883l-5.566-4.821z"
    />
    <Path
      fill="#CFD8DD"
      d="M50.693 24.99c0 37.765-10.463 31.009-18.698 31.009-8.228 0-18.689 6.756-18.689-31.009.001-22.654 37.387-22.654 37.387 0z"
    />
    <Path
      fill="#CFD8DD"
      d="M27.274 6.117c1.037 1.49.416 3.523.416 3.523h9.036c-.335-3.788-3.192-4.968-3.192-4.968.389 1.466-.087 2.644-.087 2.644-.792-2.063-3.482-2.846-3.482-2.846.912 1.521.552 2.538.552 2.538-1.45-1.218-3.243-.891-3.243-.891z"
    />
    <Path
      fill="#BC6464"
      d="M22.934 57.578c0-2.455 18.131-2.455 18.131 0 0 5.897-18.131 5.897-18.131 0z"
    />
    <Path
      fill="#F78790"
      d="M45 54.896c0 6.047-26 6.047-26 0-.002-14.529 26-14.529 26 0z"
    />
    <Path
      fill="#BC6464"
      d="M24.123 54.914c2.496 3.545 2.807-2.727 4.339-5.635 2.395-4.543-7.13 1.668-4.339 5.635zm15.755 0c-2.498 3.545-2.808-2.727-4.339-5.635-2.398-4.543 7.129 1.668 4.339 5.635z"
    />
    <Path
      fill="#6E8189"
      d="M44.298 11.838c3.617 6.806-5.847 5.007-6.526 22.948-.365 9.595 9.761 11.698 8.379 17.938 2.721-3.981 5.543-11.984 5.543-27.74-.001-5.95-3.592-10.321-7.396-13.146zm-24.596 0c-3.617 6.806 5.846 5.007 6.527 22.948.364 9.595-9.761 11.698-8.379 17.938-2.721-3.981-5.543-11.984-5.543-27.74 0-5.95 3.591-10.321 7.395-13.146z"
    />
    <Path
      fill="#302C3B"
      d="M47.264 30.104c0 2.619-1.65 4.744-3.691 4.744-2.038 0-3.689-2.125-3.689-4.744 0-2.623 1.651-4.748 3.689-4.748 2.04 0 3.691 2.125 3.691 4.748z"
    />
    <Path
      fill="#FFF"
      d="M43.309 28.028c0 1.637-1.843 1.637-1.843 0 0-1.641 1.843-1.641 1.843 0z"
    />
    <Path
      fill="#302C3B"
      d="M24.117 30.104c0 2.619-1.65 4.744-3.691 4.744-2.038 0-3.689-2.125-3.689-4.744 0-2.623 1.651-4.748 3.689-4.748 2.04 0 3.691 2.125 3.691 4.748z"
    />
    <Path
      fill="#FFF"
      d="M20.162 28.028c0 1.637-1.843 1.637-1.843 0 0-1.641 1.843-1.641 1.843 0z"
    />
  </Svg>
);

export default Cow;
