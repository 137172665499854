import React from "react";
import Svg, { Path } from "svgs";

import { animalSize } from "../constants/board";

const Turtle = (props) => (
  <Svg height={animalSize} width={animalSize} viewBox="0 0 64 64" {...props}>
    <Path
      fill="#45332C"
      d="M58.876 47.479H18.411c0-7.36 5.679-18.711 5.679-18.711-.118-.5.476-1.219 1.105-1.179l12.731-5.917c.266-.373 1.264-.295 1.452 0l12.71 5.917c.57.022 1.164.741 1.106 1.179.001-.001 5.682 11.459 5.682 18.711z"
    />
    <Path
      fill="#297124"
      d="M35.666 57.581s-6.785 1.248-8.422 0c-2.208-1.683 1.295-9.626 3.469-11.347 0 0 4.631-.389 6.119 1.014 0 0-2.306 6.451-1.166 10.333zm12.675.555s-5.392-.334-6.447-1.6c-1.423-1.708 2.517-7.124 4.454-8.028 0 0 3.606.582 4.523 1.939 0 0-2.787 4.498-2.53 7.689zM5.749 20.06s8.479-8.495 10.228-1.742c0 0-7.383 8.64-10.228 1.742z"
    />
    <Path
      fill="#92C932"
      d="M24.554 8.6c-5.859-6.414-29.319-.141-20.656 9.9 0 0 3.758-1.999 10.922-1.861-5.635 4.975-9.071 3.421-9.071 3.421 0 3.186 1.497 5.793 7.364 5.793 7.313 0-.606 19.788 5.89 19.788 3.805 0 4.593-15.717 4.878-16.625 1.564-4.978 4.613-16.102.673-20.416zm36.342 35.775c1.245 1.586 1.28 5.633.87 6.453-.408.82-.984-1.133-1.791-2.893-1.399-3.051-4.067-3.725-2.227-4.863.854-.525 2.512.491 3.148 1.303z"
    />
    <Path
      fill="#9E6C49"
      d="M38.644 28.92l-9.493 3.859-1.724 10.149H49.86l-1.727-10.149z"
    />
    <Path
      fill="#C4926E"
      d="M38.72 29.862l8.729 3.548s-7.482-2.017-8.545-2.017c-.793 0-6.063 2.414-7 3.146-.672.524-3.252 6.761-3.252 6.761l1.343-7.89 8.725-3.548z"
    />
    <Path
      fill="#9E6C49"
      d="M52.089 27.588c-3.587-3.592-7.823-5.779-12.71-5.917v6.079l9.164 3.518 3.546-3.68z"
    />
    <Path
      fill="#C4926E"
      d="M50.624 27.22c-2.972-2.977-6.482-4.789-10.531-4.903v5.038s1.223-2.03 1.542-4.162c3.825-.061 8.989 4.027 8.989 4.027z"
    />
    <Path
      fill="#9E6C49"
      d="M37.927 27.744v-6.072c-4.887.138-9.144 2.325-12.731 5.917l3.549 3.68 9.182-3.525z"
    />
    <Path
      fill="#C4926E"
      d="M28.071 27.355c4.076-4.23 8.954-4.899 8.954-4.899-4.194.118-7.849 1.996-10.929 5.079l3.047 3.158c.001 0-.197-2.248-1.072-3.338z"
    />
    <Path
      fill="#9E6C49"
      d="M53.195 28.767l-3.873 4.004 1.972 10.149 8.551.008c-1.061-5.557-3.417-10.465-6.65-14.161z"
    />
    <Path
      fill="#C4926E"
      d="M53.467 29.861l-3.131 3.237 1.594 8.204s-.646-6.176-.349-8.132c.126-.833 1.886-3.309 1.886-3.309z"
    />
    <Path
      fill="#9E6C49"
      d="M27.969 32.771l-3.879-4.004c-1.614 1.849-2.969 4.866-5.094 5.549-2.352.758-1 8.611 6.988 8.611.003.001 1.985-10.156 1.985-10.156z"
    />
    <Path
      fill="#C4926E"
      d="M25.757 31.454l-1.597-1.625c-1.438 1.624-2.646 4.275-4.54 4.876-1.112.353-1.295 2.463-.157 4.364-.478-1.578-.523-2.745.67-3.364 2.616-1.357 3.229-2.551 4.063-3.979.353-.603 1.561-.272 1.561-.272z"
    />
    <Path
      fill="#F9C802"
      d="M17.47 45.105h42.338c0 5.639-4.847 7.822-21.17 7.822S17.47 50.744 17.47 45.105z"
    />
    <Path
      fill="#F59E01"
      d="M30.076 45.105c.341 2.675 2.888 6.3 4.34 7.705-2.155-.67-5.555-4.561-4.34-7.705zm17.001 0c-.34 2.675-2.887 6.3-4.34 7.705 2.157-.67 5.555-4.561 4.34-7.705z"
    />
    <Path
      fill="#302C3B"
      d="M17.239 11.446c0 3.332-5 3.332-5 0 0-3.335 5-3.335 5 0z"
    />
    <Path fill="#FFF" d="M14.427 10.32c0 1-1.5 1-1.5 0s1.5-1 1.5 0z" />
    <Path
      fill="#297124"
      d="M3.722 12.549c-.266-.271.858-1.592 1.188-1.403.364.207-.899 1.697-1.188 1.403z"
    />
    <Path
      fill="#92C932"
      d="M29.803 60.89s-8.926 1.906-11.081 0c-2.905-2.57 1.705-14.706 4.565-17.333 0 0 6.092-.596 8.051 1.549 0-.001-3.035 9.853-1.535 15.784z"
    />
    <Path
      fill="#297124"
      d="M21.537 60.913c-1.136-2.785-2.899-4.671-3.374-4.671-.641 0-1.763 1.704-1.763 4.978 0 1.105 5.697 1.066 5.137-.307z"
    />
    <Path
      fill="#92C932"
      d="M61.54 51.951s-4.564 7.923-6.893 7.759c-3.138-.221-9.79-7.485-7.227-15.823 0 0 5.6-.826 7.899-.395.001 0-1.595 7.104 6.221 8.459z"
    />
    <Path
      fill="#297124"
      d="M56.594 58.228c-2.019-1.311-4.066-1.666-4.394-1.414-.44.342-.455 2.107.998 4.359.49.76 4.39-2.299 3.396-2.945z"
    />
  </Svg>
);

export default Turtle;
