import React from "react";
import Svg, { Path } from "svgs";

import { animalSize } from "../constants/board";

const Giraffe = (props) => (
  <Svg height={animalSize} width={animalSize} viewBox="0 0 64 64" {...props}>
    <Path
      fill="#8A6859"
      d="M40.336 33.814C33.67 40.352 40.394 62 40.394 62h2.243s.537-.695-.564-5.518c1.593 4.9 3.146 1.311.299-7.092 2.394 2.225 3.556.124 0-7.354 2.228 2.649 2.498-3.732-2.036-8.222z"
    />
    <Path
      fill="#F59E01"
      d="M40.394 62s5.813-27.592-6.425-44.999h-7.037S30.602 48.648 23.871 62h16.523z"
    />
    <Path
      fill="#D47706"
      d="M41.664 51.026s.443-5.698-.188-11.136c-10.461 4.576-4.071 5.172-4.696 9.563-.31 2.177 4.723 1.841 4.884 1.573zm-1.158 10.393s.848-4.073 1.064-8.819c-7.451-.953-7.197 4.531-6.555 8.268.184 1.065 5.197.671 5.491.551zM23.871 62s1.152-2.73 2.292-6.713c3.196 5.773 8.428 1.326 5.552 6.713h-7.844z"
    />
    <Path
      fill="#D47706"
      d="M27.633 52.49c-.307-3.964-.003-8.195 5.385-7.764 6.852 12.696-.957 4.943.611 11.862.238 1.055-5.617.813-5.996-4.098z"
    />
    <Path
      fill="#F9B700"
      d="M39.113 20.281c6.063-3.309 7.854-13.58 20.887-9.683-5.391 3.517-5.951 15.812-20.887 9.683z"
    />
    <Path
      fill="#EA8800"
      d="M41.43 20.878c6.944 2.05 2.533-11.614 16.652-8.502-3.405 5.668-6.74 12.952-16.652 8.502z"
    />
    <Path
      fill="#F9B700"
      d="M29.65 19.562c-5.25-2.865-6.801-11.76-18.084-8.383 4.666 3.043 5.153 13.689 18.084 8.383z"
    />
    <Path
      fill="#EA8800"
      d="M27.645 20.08c-6.014 1.774-2.194-10.057-14.419-7.362 2.948 4.908 5.837 11.212 14.419 7.362z"
    />
    <Path
      fill="#F9B700"
      d="M26.424 15.281c1.428 5.044-8.06 5.775-5.408 9.829 4.08 6.429 3.57 12.562 3.57 12.562-3.241 1.829-1.59 2.878-1.836 4.451-3.06 5.734 4.078 9.69 6.323 6.525 2.243-3.264 3.06-.297 4.794-4.943 1.732-4.65 6.322-3.363 9.179-5.935 3.978-3.66-4.079-4.55 1.63-11.769 2.551-3.266-4.793-7.121-3.467-9.988 0 0-15.5-3.204-14.785-.732z"
    />
    <Path
      fill="#F9C802"
      d="M26.424 15.281c.636 2.247-.895 3.639-2.553 4.848-2.064 1.507 8.246 4.891.992 17.364-.479.821 1.621-.278 3.15-.094 1.574.191 4.339 2.27 4.1 1.144-3.365-15.847 12.604-15.95 11.605-17.272-1.416-1.874-3.225-3.71-2.51-5.257 0 0 2.011-1.193-.102-5.342-1.101-2.162.115-5.379.469-6.531.612-1.99-4.507-.535-4.507-.535 2.896 13.188-10.056 12.299-6.913.417 0 0-4.243-1.735-3.754-.006.742 2.62 1.021 5.292.306 7.271-.916 1.878-.998 1.521-.283 3.993z"
    />
    <Path
      fill="#8A6859"
      d="M26.998 6.823c.083.628 2.641.457 2.709-.316a15.01 15.01 0 0 1 .449-2.484c.672-2.538-4.243-1.735-3.754-.006.266.94.473 1.887.596 2.806zm10.475-.379c.055.968 3.238.95 3.412.131.218-1.032.537-1.931.691-2.435.612-1.99-5.127-3.359-4.507-.535.227 1.031.357 1.978.404 2.839z"
    />
    <Path
      fill="#594640"
      d="M23.336 40.992c.252.359.911.066 1.676 1.523.03-1.996-2.729-3.03-1.676-1.523zm8.79.169c-.431.442-1.56.081-2.869 1.88-.052-2.465 4.676-3.74 2.869-1.88zm-2.647 6.112c-.923 1.068-4.681 3.006-6.628-.691 1.722 1.997 5.011 1.531 6.628.691z"
    />
    <Path
      fill="#7AB51D"
      d="M11.021 37.869c0 .593 5.404 10.779 12.85 10.779 7.343 0 9.996 2.076 11.729 3.659l.612-.89s-5.201-3.462-12.239-3.462c-7.547-.098-12.952-10.976-12.952-10.086z"
    />
    <Path
      fill="#7AB51D"
      d="M13.994 42.172C9.02 38.236 7.323 42.07 4 38.711c1.105 3.754 5.274 5.796 9.994 3.461zm3.404 3.287c-6.521-2.99-7.383 1.697-11.932-1.106 2.209 3.838 7.343 4.96 11.932 1.106zm-2.419-2.001c-1.557-6.202 2.586-6.239.885-10.676 2.946 2.534 3.116 7.228-.885 10.676zm3.335 2.985c-1.445-7.111 3.251-6.936 1.553-12.053 3.207 3.028 3.16 8.356-1.553 12.053z"
    />
    <Path
      fill="#F9B700"
      d="M28.059 19.357c-1.836 1.721 1.867 3.997 1.346 10.959-.301 4.006 1.875-5.77 7.626-9.643 2.289-1.541-3.328-6.611-8.972-1.316z"
    />
    <Path
      fill="#EA8800"
      d="M37.073 33.438c3.933-.242 1.409 7.101-.065 5.133-1.313-1.75-4.34 2.734-3-.229.816-1.806.42-4.742 3.065-4.904z"
    />
    <Path
      fill="#EA8800"
      d="M43.365 37.455c-.988 1.426-4.109 2.245-6.078 2.78 4.096-2.728 2.133-5.86 3.49-6.75 1.029-.673 3.196 3.093 2.588 3.97zm-6.842 3.138c-1.109.802-1.787 1.065-2.738 3.327-1.248-1.625.33-3.623 1.688-4.513 1.028-.673 1.916.561 1.05 1.186z"
    />
    <Path
      fill="#302C3B"
      d="M44.928 29.957c0 1.682-1.225 3.066-2.754 3.066-1.531 0-2.754-1.384-2.754-3.066 0-1.68 1.223-3.065 2.754-3.065 1.529-.001 2.754 1.385 2.754 3.065z"
    />
    <Path
      fill="#594640"
      d="M47.273 25.795c-1.748 3.144-6.59-.087-8.016 4.161.405-5.568 5.499-2.067 8.016-4.161z"
    />
    <Path
      fill="#FFF"
      d="M41.301 28.969c0 .79-1.225.79-1.225 0 0-.792 1.225-.792 1.225 0z"
    />
    <Path
      fill="#302C3B"
      d="M25.742 29.031c0 1.504-1.097 2.744-2.468 2.744-1.37 0-2.466-1.24-2.466-2.744 0-1.506 1.096-2.747 2.466-2.747 1.372 0 2.468 1.241 2.468 2.747z"
    />
    <Path
      fill="#594640"
      d="M19.609 25.248c1.205 2.832 5.397.092 6.262 3.897.063-4.951-4.351-1.978-6.262-3.897z"
    />
    <Path
      fill="#FFF"
      d="M22.492 28.145c0 .708-1.096.708-1.096 0 0-.71 1.096-.71 1.096 0z"
    />
  </Svg>
);

export default Giraffe;
