import React from "react";
import Svg, { Path } from "svgs";

import { animalSize } from "../constants/board";

const Mouse = (props) => (
  <Svg height={animalSize} width={animalSize} viewBox="0 0 64 64" {...props}>
    <Path
      fill="#99A8AE"
      d="M13.256 2.117C-10.523-.958 8.329 57.798 24.375 18.291c1.964-4.843-3.536-15.194-11.119-16.174z"
    />
    <Path
      fill="#FDE0DA"
      d="M13.861 30.4c12.123-10.922 2.348-22.233-3.745-23.015C-2.832 5.724 7.924 35.75 13.861 30.4z"
    />
    <Path
      fill="#F6BEC1"
      d="M17.775 25.75c5.502-9.275-2.385-17.771-7.614-18.441-2.744-.352-4.365.604-5.345 2.63C9.86 8.635 16 16.338 17.775 25.75z"
    />
    <Path
      fill="#99A8AE"
      d="M39.625 18.291C55.671 57.798 74.523-.958 50.743 2.117c-7.583.98-13.082 11.331-11.118 16.174z"
    />
    <Path
      fill="#FDE0DA"
      d="M53.884 7.385c-6.093.782-15.868 12.093-3.745 23.015 5.937 5.35 16.693-24.676 3.745-23.015z"
    />
    <Path
      fill="#F6BEC1"
      d="M46.225 25.75c-5.503-9.275 2.385-17.771 7.613-18.441 2.744-.352 4.365.604 5.346 2.63C54.14 8.635 48 16.338 46.225 25.75z"
    />
    <Path
      fill="#99A8AE"
      d="M38 15.639c-.207-3.463-3.035-5.812-3.035-5.812a8.883 8.883 0 0 1-1.265 2.547c-1.127-2.843-4.427-4.736-4.427-4.736.744 2.467.56 4.576.56 4.576-1.936-.953-3.833-.575-3.833-.575 1.301 1.75 1.042 4 1.042 4H38z"
    />
    <Path
      fill="#99A8AE"
      d="M51.963 32.212c-2.69-6.081-9.069-19.424-19.962-19.424-10.89 0-17.274 13.343-19.962 19.424-1.369 3.091-2.561 6.111-2.995 10.586-.759 7.815 8.445 14.54 13.433 14.54 2.386 0 4.915 4.661 9.523 4.661 4.605 0 7.136-4.661 9.521-4.661 4.988 0 14.19-6.725 13.434-14.54-.434-4.475-1.625-7.493-2.992-10.586z"
    />
    <Path
      fill="#302C3B"
      d="M47 40.568c0 7.703-8.742 7.703-8.742 0 0-7.701 8.742-7.701 8.742 0z"
    />
    <Path
      fill="#FFF"
      d="M42.517 38.604c0 1.925-2.687 1.925-2.687 0s2.687-1.925 2.687 0z"
    />
    <Path
      fill="#302C3B"
      d="M25.742 40.568c0 7.703-8.742 7.703-8.742 0 0-7.701 8.742-7.701 8.742 0z"
    />
    <Path
      fill="#FFF"
      d="M21.259 38.604c0 1.925-2.688 1.925-2.688 0s2.688-1.925 2.688 0z"
    />
    <Path
      fill="#F6BEC1"
      d="M32 54.657c-1.063 4.674-4.749 2.381-5.19 1.581 0 1.333 2.964 3.679 5.19 1.312 2.226 2.367 5.189.021 5.189-1.312-.442.8-4.126 3.093-5.189-1.581z"
    />
    <Path
      fill="#F6BEC1"
      d="M27.29 52.64c0-2.309 2.11-2.754 4.71-2.754 2.602 0 4.708.445 4.708 2.754 0 1.836-3.746 3.467-4.708 3.467-.96-.001-4.71-1.631-4.71-3.467z"
    />
    <Path
      fill="#FDE0DA"
      d="M27.913 52.706c0-1.882 1.774-2.245 3.963-2.245 2.917 0-3.963 5.034-3.963 2.245z"
    />
    <Path
      fill="#6E8189"
      d="M21.669 51.209c-14.057-2.495-17.071.461-16.99 1.086.029.202 6.173-1.901 16.99-1.086zm.498 2.141C4.93 53.259 1.806 57.469 2.009 58.214c.067.236 7.074-3.594 20.158-4.864zm20.164-2.141c14.058-2.495 17.072.461 16.989 1.086-.027.202-6.172-1.901-16.989-1.086zm-.497 2.141c17.236-.091 20.359 4.119 20.157 4.864-.067.236-7.073-3.594-20.157-4.864z"
    />
  </Svg>
);

export default Mouse;
