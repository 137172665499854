import React from "react";
import Svg, { Ellipse, Path } from "svgs";

import { animalSize } from "../constants/board";

const Penguin = (props) => (
  <Svg height={animalSize} width={animalSize} viewBox="0 0 64 64" {...props}>
    <Path
      fill="#F59E01"
      d="M26.333 60.527c-.814 0-2.602.66-3.176.115-.846-.801 0-4.643 0-4.643h-2.314s.844 3.842 0 4.643c-.576.545-2.361-.115-3.176-.115C15.642 60.527 14 62 14 62h16.002c-.001 0-1.643-1.473-3.669-1.473zm19.998.002c-.813 0-2.6.66-3.176.115-.844-.801 0-4.645 0-4.645h-2.314s.846 3.844 0 4.645c-.574.545-2.361-.115-3.176-.115-2.023 0-3.666 1.471-3.666 1.471h16s-1.643-1.471-3.668-1.471z"
    />
    <Path
      fill="#302C3B"
      d="M10.384 27.024C-.38 33.705 1.73 43.047 3.25 41.939c16.781-12.213 20.904-23.46 7.134-14.915zm43.232 0c-13.771-8.546-9.647 2.701 7.135 14.915 1.519 1.108 3.629-8.234-7.135-14.915z"
    />
    <Path
      fill="#302C3B"
      d="M47.999 17.467C47.999 8.924 40.836 2 32 2S16 8.924 16 17.467c0 11.6-4 15.493-4 23.199C12 51.344 20.955 60 32 60c11.046 0 20-8.656 20-19.334-.001-7.707-4.001-11.6-4.001-23.199z"
    />
    <Path
      fill="#FFF"
      d="M43.999 18.788c0-12.407-10.126-9.99-10.126-.708h-3.747C30.126 8.798 20 6.379 20 18.788c0 9.667-4 15.71-4 22.134 0 11.564 7.717 17.145 16 17.145 8.285 0 16-5.58 16-17.145-.001-6.424-4.001-12.467-4.001-22.134z"
    />
    <Path
      fill="#D47706"
      d="M27.982 22.008h8.033c0 2.543-1.797 4.6-4.015 4.6-2.221-.001-4.018-2.057-4.018-4.6z"
    />
    <Path
      fill="#F59E01"
      d="M36.999 22.329l-5 1.22-5-1.22c0-3.159 2.237-5.722 5-5.722 2.761 0 5 2.563 5 5.722z"
    />
    <Ellipse fill="#302C3B" cx={25} cy={16.968} rx={2} ry={2.75} />
    <Path fill="#FFF" d="M24.634 15.785c0 .833-1 .833-1 0s1-.833 1 0z" />
    <Ellipse fill="#302C3B" cx={38.999} cy={16.968} rx={2} ry={2.75} />
    <Path fill="#FFF" d="M38.634 15.785c0 .833-1 .833-1 0s1-.833 1 0z" />
  </Svg>
);

export default Penguin;
