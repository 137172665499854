import React from "react";
import Svg, { Path } from "svgs";

import { animalSize } from "../constants/board";

const Chicken = (props) => (
  <Svg height={animalSize} width={animalSize} viewBox="0 0 64 64" {...props}>
    <Path
      fill="#E81C27"
      d="M37.807 9.774c-2.824-4.313.859-5.669-.544-7.063-1.819-1.81-9.025-.502-8.9 7.906-4.249-2.756-.942-5.101-3.057-5.898-2.799-1.059-9.374 2.479-5.152 11.463-3.806 1.281-2.742-5.251-5.129-2.227-4.28 5.422 2.932 18.561 9.624 10.18 6.719-8.413 9.686-2.502 15.525-5.982 7.745-4.619 7.547-11.682 3.765-11.926-2.311-.152-2.152.776-6.132 3.547z"
    />
    <Path
      fill="#E6EBEF"
      d="M17.32 35.043c.199 5.641-3.088 16.191-.954 20.277 1.888 3.613 7.453 4.462 10.829 3.46 1.831 4.144 10.444 3.265 10.43.325 1.895 3.207 10.219 2.975 10.834-.99 3.182.977 7.004-.78 8.602-2.781 3-3.752-2.158-14.65-1.988-20.291.901-29.461-38.805-29.461-37.753 0z"
    />
    <Path
      fill="#CFD8DD"
      d="M55.072 35.049c-1.695 10.26 5.717 21.348-5.167 23.328 2.805.193 5.788-1.329 7.155-3.038 3.001-3.753-2.159-14.651-1.988-20.29zM17.494 56.807s8.639 6.053 10.756-2.427c2.021 5.513-5.034 7.856-10.756 2.427zm10.996 3.557s8.483 3.846 8.853-5.603c2.475 5.126-1.79 9.824-8.853 5.603zm10.41-.026s8.291 3.39 9.254-5.958c3.282 5.905-4 9.669-9.254 5.958z"
    />
    <Path
      fill="#C21413"
      d="M11.147 39.299c-6.359 3.113.43 11.446 5.917 7.359 8.078-6.017 2.177-14.313 2.177-14.313s-.996 3.481-8.094 6.954z"
    />
    <Path
      fill="#E81C27"
      d="M15.184 41.305c-6.768 6.242 4.015 12.962 9.132 5.871 6.942-9.615-3.606-15.794-3.606-15.794s1.362 3.569-5.526 9.923z"
    />
    <Path
      fill="#D47706"
      d="M25.674 33.131s-12.667-4.13-19.342 5.905c6.017-3.39 17.278 6.228 19.342-5.905z"
    />
    <Path
      fill="#F59E01"
      d="M24.628 35.93c4.003-4.526-1.619-12.211-6.769-11.076C7.184 27.202 6 39.508 6 39.508c7.229-6.871 13.13 2.637 18.628-3.578z"
    />
    <Path
      fill="#AB5C10"
      d="M19.937 27.699c.306.88-1.971 1.381-2.278.5-.308-.882 1.97-1.382 2.278-.5z"
    />
    <Path
      fill="#302C3B"
      d="M35.232 26.086a4.5 4.5 0 1 1-9 0c0-2.483 2.01-4.5 4.5-4.5 2.485 0 4.5 2.017 4.5 4.5z"
    />
    <Path
      fill="#FFF"
      d="M29.789 24.188c0 1.746-2.097 1.746-2.097 0s2.097-1.747 2.097 0z"
    />
  </Svg>
);

export default Chicken;
